import { ReactNode } from 'react'
import { ToastOptions, toast } from 'react-toastify'

const defaultOptions: ToastOptions = {
  autoClose: 2000,
  pauseOnHover: false,
  position: 'top-right',
}

export default {
  success(message: ReactNode, options = {}) {
    return toast.success(message, {
      className: 'bg-success',
      ...defaultOptions,
      ...options,
    })
  },
  error(message: ReactNode, options = {}) {
    return toast.error(message, {
      className: 'bg-danger',
      ...defaultOptions,
      ...options,
      position: 'top-center',
      autoClose: 10000,
    })
  },
  info(message: ReactNode, options = {}) {
    return toast.info(message, {
      className: 'bg-info',
      ...defaultOptions,
      ...options,
    })
  },
  warning(message: ReactNode, options = {}) {
    return toast.warning(message, {
      className: 'bg-warning',
      ...defaultOptions,
      ...options,
      position: 'top-center',
    })
  },
  primary(message: ReactNode, options = {}) {
    return toast(message, {
      className: 'bg-primary text-white',
      progressClassName: 'bg-white bg-opacity-50',
      ...defaultOptions,
      ...options,
    })
  },
  secondary(message: ReactNode, options = {}) {
    return toast(message, {
      className: 'bg-secondary text-white',
      progressClassName: 'bg-white bg-opacity-50',
      ...defaultOptions,
      ...options,
    })
  },
  light(message: ReactNode, options = {}) {
    return toast(message, {
      className: 'bg-light',
      progressClassName: 'bg-dark bg-opacity-50',
      ...defaultOptions,
      ...options,
    })
  },
  dark(message: ReactNode, options = {}) {
    return toast(message, {
      className: 'bg-dark text-white',
      progressClassName: 'bg-white bg-opacity-50',
      ...defaultOptions,
      ...options,
    })
  },
}
