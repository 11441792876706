import {
  GET_PRODUCT_FOR_PRICING,
  GET_PRICE_SUPPLIER,
  GET_PRICE_PRODUCT_MULTIPLIER_SETTINGS,
  GET_PRICE_FOREX_DETAIL_ITEM,
  GET_PRICING_NOTES,
  SET_MANUFACTURERS_FILTERS,
  GET_INBOUND_OCEAN_FREIGHT_OPTIONS,
  GET_MANUFACTURERS_NO_PRICING,
  SET_MANUFACTURER,
  GET_PRICE_INBOUND_FREIGHT_DETAIL_ITEM,
  SET_VERSION_FILTERS,
  GET_VERSION,
  GET_BASKET_OF_GOODS_ITEM,
  GET_PRICING_CATEGORIES,
  SET_SUPPLIER_MANUFACTURER_DISCOUNT_FILTERS,
  GET_PRICING_MANUFACTURERS,
  GET_PRICING_SUPPLIERS,
  SET_CATEGORY_MULTIPLIERS_FILTERS,
} from './constants'
import { IPricingAction, IPricingState } from './types'

export default function pricingReducer(
  state: IPricingState,
  action: IPricingAction,
): IPricingState {
  const payload = action.payload
  switch (action.type) {
    case GET_PRODUCT_FOR_PRICING:
      return {
        ...state,
        productForPricing: payload,
      }
    case GET_PRICING_CATEGORIES:
    return {
      ...state,
      pricingCategories: payload,
    }
    case GET_PRICE_SUPPLIER:
      return {
        ...state,
        priceSupplier: payload,
      }
    case GET_PRICING_SUPPLIERS:
      return {
        ...state,
        pricingSuppliers: payload,
      }
    case GET_PRICING_MANUFACTURERS:
      return {
        ...state,
        pricingManufacturers: payload,
      }
    case GET_PRICE_PRODUCT_MULTIPLIER_SETTINGS:
      return {
        ...state,
        priceProductMultiplierSettingsItem: payload,
      }
    case SET_SUPPLIER_MANUFACTURER_DISCOUNT_FILTERS:
      return {
        ...state,
        supplierManufacturerDiscountFilters: payload,
      }
    case GET_PRICE_FOREX_DETAIL_ITEM:
      return {
        ...state,
        priceForexDetailItem: payload,
      }
    case GET_BASKET_OF_GOODS_ITEM:
      return {
        ...state,
        basketOfGoodsItem: payload,
      }
    case SET_VERSION_FILTERS:
      return {
        ...state,
        versionFilters: payload,
      }
    case SET_MANUFACTURER:
      return {
        ...state,
        manufacturer: payload,
      }
    case GET_VERSION:
      return {
        ...state,
        version: payload,
      }
    case SET_MANUFACTURERS_FILTERS:
      return {
        ...state,
        manufacturersFilters: payload,
      }
    case SET_CATEGORY_MULTIPLIERS_FILTERS:
      return {
        ...state,
        categoryMultipliersFilters: payload,
      }
    case GET_INBOUND_OCEAN_FREIGHT_OPTIONS:
      return {
        ...state,
        inboundOceanFreightOptions: payload,
      }
    case GET_PRICE_INBOUND_FREIGHT_DETAIL_ITEM:
      return {
        ...state,
        priceInboundFreightDetailItem: payload,
      }
    case GET_MANUFACTURERS_NO_PRICING:
      return {
        ...state,
        manufacturersNoPricing: payload,
      }
    case GET_PRICING_NOTES:
      return {
        ...state,
        pricingNotes: payload,
      }

    default:
      return state
  }
}
