import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { INoteData } from '../../../context/site/types'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'
import { useUserContext } from '../../../context/user/UserProvider'
import NotesListItem from './NotesListItem'

interface INotesListProps {
  notes: INoteData[] | null
  updateNote?: (note: INoteData) => void
  deleteNote?: (note: INoteData) => void
}

const NotesList: React.FC<INotesListProps> = ({
  notes,
  updateNote,
  deleteNote,
}) => {
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData<IUserData>(userKeys.user)
  const { isDarkMode } = useUserContext()

  const handleDeleteNote = (note: INoteData) => {
    if (deleteNote) {
      deleteNote(note)
    }
  }

  const handlePinUnpinNote = (note: INoteData) => {
    if (updateNote) {
      updateNote({
        ...note,
        is_pinned: !note.is_pinned,
      })
    }
  }

  return (
    <div>
      {notes?.length === 0 && <div>No notes yet...</div>}
      <ListGroup className={isDarkMode ? 'notes-list' : 'notes-list-dm'}>
        {notes?.map((note: INoteData) => {
          return (
            <NotesListItem
              key={note.id}
              note={note}
              isDarkMode={isDarkMode}
              user={user}
              handleDeleteNote={handleDeleteNote}
              handlePinUnpinNote={handlePinUnpinNote}
              handleUpdateNote={updateNote}
            />
          )
        })}
      </ListGroup>
    </div>
  )
}

export default NotesList
