import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { ListGroup, Overlay } from 'react-bootstrap'
import { BsCaretDownFill } from 'react-icons/bs'
import { useUserContext } from '../../../context/user/UserProvider'
import { ISelectOption } from '../../../types/interfaces'
import { findIndex, get } from 'lodash'
import { Placement } from 'react-bootstrap/esm/types'

interface ICustomSelect {
  title: ReactNode
  initialValue?: string
  width?: string
  maxHeight?: string
  handleSelect: (selectedValue: string) => void
  options: Array<ISelectOption>
  hideClear?: boolean
  placement?: Placement | undefined
  unknownValueDisplay?: string
}

const CustomSelect: React.FC<ICustomSelect> = ({
  title,
  initialValue = '',
  width = '200px',
  maxHeight = '80vh',
  handleSelect,
  options,
  hideClear = false,
  placement = 'right-start',
  unknownValueDisplay = 'Unknown',
}) => {
  const [show, setShow] = useState(false)
  const [value, setValue] = useState(initialValue)
  const [displayValue, setDisplayValue] = useState('')

  const target = useRef(null)

  const { isDarkMode } = useUserContext()

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    setValue(initialValue)
    if (initialValue) {
      const index = findIndex(options, (option: any) => {
        return option.value.toString() === initialValue.toString()
      })
      const dValue = get(options, `${index}.label`)
      if (dValue) {
        setDisplayValue(dValue)
      } else {
        setDisplayValue(unknownValueDisplay)
      }
    }
  }, [initialValue])

  const handleOutsideClick = (e: any) => {
    setShow(false)
  }

  const handleSelectItem = (selectedValue: string) => {
    setValue(selectedValue)
    const index = findIndex(options, (option: any) => {
      return option.value === selectedValue
    })
    const dValue = get(options, `${index}.label`)
    if (dValue) {
      setDisplayValue(dValue)
    }
    setShow(false)
    handleSelect(selectedValue)
  }

  return (
    <>
      <div
        ref={target}
        onClick={() => setShow(!show)}
        onBlur={() => setShow(false)}
        className={`border rounded p-3 position-relative ${
          isDarkMode ? 'rw-custom-select-dm' : 'rw-custom-select'
        }`}
        style={width ? { width: width } : {}}
      >
        <span className={`${value ? 'invisible' : ''} pe-1`}>{title}</span>
        <span className="float-end">
          <BsCaretDownFill />
        </span>

        {value ? (
          <div
            style={{
              position: 'absolute',
              top: '4px',
            }}
          >
            <span style={{ fontSize: '90%', opacity: 0.7 }}>{title}</span>
          </div>
        ) : (
          <></>
        )}

        {value ? (
          <div
            className="position-absolute text-nowrap overflow-hidden"
            style={{
              bottom: '7px',
              width: width ? `calc(${width} - 30px)` : undefined,
              textOverflow: 'ellipsis',
            }}
          >
            {displayValue}
          </div>
        ) : (
          <></>
        )}
      </div>
      <Overlay target={target.current} show={show} placement={placement}>
        {({ ...props }) => (
          <div {...props} className="border rounded ms-1 rw-force-top">
            <ListGroup
              className="overflow-auto"
              style={{ maxHeight: maxHeight }}
            >
              {hideClear ? (
                <></>
              ) : (
                <ListGroup.Item
                  className={`border-bottom ${
                    isDarkMode ? 'dm-card-bg' : 'bg-light'
                  }`}
                  action
                  onClick={() => {
                    handleSelectItem('')
                  }}
                >
                  Clear Selection
                </ListGroup.Item>
              )}
              {options.map((option: ISelectOption, index: number) => {
                return (
                  <ListGroup.Item
                    action
                    onClick={() => {
                      handleSelectItem(option.value)
                    }}
                    key={option.id}
                  >
                    <span>{option.label}</span>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </div>
        )}
      </Overlay>
    </>
  )
}

export default CustomSelect
