import React from 'react'

export interface IRwTableRow {
  className?: string
  highlightedClassName?: string
  isHighlighted?: boolean
  align?: 'top' | 'middle' | 'bottom'
  [x: string]: any
}

const RwTableRow: React.FC<IRwTableRow> = ({
  children,
  className = '',
  highlightedClassName = '',
  isHighlighted = false,
  align = 'middle',
  ...rest
}) => {
  const alignClass = `align-${align}`

  return (
    <tr
      className={`${
        isHighlighted === false ? className : highlightedClassName
      } ${alignClass}`}
      {...rest}
    >
      {children}
    </tr>
  )
}

export default RwTableRow
