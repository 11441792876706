import React, { useEffect, useState } from 'react'
import NotesField from './NotesField'
import { Collapse, Modal } from 'react-bootstrap'
import { INoteData } from '../../../context/site/types'
import RwNav from '../../molecules/RwNav'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import NotesList from './NotesList'
import { useUserContext } from '../../../context/user/UserProvider'

interface INotesModalProps {
  show: boolean
  existingNotes: INoteData[] | null
  title?: string
  saveNotes: (notes: string) => void
  handleClose: () => void
  refreshNotes?: () => void
  updateNote?: (note: INoteData) => void
  deleteNote?: (note: INoteData) => void
}

const NotesModal: React.FC<INotesModalProps> = ({
  show,
  existingNotes,
  title = '',
  saveNotes,
  handleClose,
  refreshNotes = null,
  updateNote = null,
  deleteNote = null,
}) => {
  const [showConvo, setShowConvo] = useState(false)

  const handleToggleConvo = () => {
    setShowConvo(!showConvo)
  }

  useEffect(() => {
    if (refreshNotes && showConvo) {
      const intervalID = setInterval(() => {
        refreshNotes()
      }, 60000)

      return () => clearInterval(intervalID)
    }
  }, [refreshNotes, showConvo])

  const onClose = () => {
    setShowConvo(false)
    handleClose()

    return () => {
      setShowConvo(false)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      className="notes-modal"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <NotesField saveNotes={saveNotes} />
          <RwNav>
            <RwNavItem
              onClick={handleToggleConvo}
              title={showConvo ? 'Hide Conversation' : 'View Conversation'}
              variant="comments"
              fill
            />
            {refreshNotes && (
              <RwNavItem
                onClick={refreshNotes}
                title="Refresh"
                variant="refresh"
              />
            )}
          </RwNav>
        </div>
        <Collapse in={showConvo}>
          <div>
            <NotesList
              notes={existingNotes}
              {...(updateNote ? { updateNote } : {})}
              {...(deleteNote ? { deleteNote } : {})}
            />
          </div>
        </Collapse>
      </Modal.Body>
    </Modal>
  )
}

export default NotesModal
