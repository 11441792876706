import React from 'react'

// react-icons imports for consts
import { IoSearchCircleOutline } from 'react-icons/io5'
import { SiQuickbooks } from 'react-icons/si'
import { ImCheckmark } from 'react-icons/im'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { VscBlank } from 'react-icons/vsc'
import { BiCheckbox, BiCheckboxChecked } from 'react-icons/bi'
import {
  BsFileEarmarkPdf,
  BsFileEarmarkExcel,
  BsTag,
  BsTags,
  BsFillBookmarksFill,
  BsXCircleFill,
  BsFillArrowUpRightSquareFill,
  BsEye,
  BsEyeSlash,
  BsFileRuled,
  BsFillGrid3X3GapFill,
  BsFillPeopleFill,
  BsBox2Fill,
  BsGraphUpArrow,
  BsCalendar3,
  BsCardChecklist,
} from 'react-icons/bs'
import {
  FaBarcode,
  FaBars,
  FaTruck,
  FaAddressCard,
  FaKey,
  FaSignOutAlt,
  FaUser,
  FaRegMinusSquare,
  FaInfoCircle,
  FaArrowCircleDown,
  FaMinus,
  FaPlus,
  FaSearch,
  FaYoutube,
  FaTools,
  FaLock,
  FaLockOpen,
} from 'react-icons/fa'
import {
  MdMarkEmailRead,
  MdEmail,
  MdOutlineOndemandVideo,
  MdSpeed,
} from 'react-icons/md'
import { GiFishbone } from 'react-icons/gi'
import { RiFileTextFill } from 'react-icons/ri'
import { IoWarningOutline } from 'react-icons/io5'
import CdnImg from '../organisms/Common/CdnImg'
import { useUserContext } from '../../context/user/UserProvider'

interface IRwIcon {
  icon: string
  cssClassName?: string
  [x: string]: any
}

const RwIcon: React.FC<IRwIcon> = ({ icon, cssClassName, ...rest }) => {
  const { isDarkMode } = useUserContext()

  let rwIconComponent = <></>
  switch (icon) {
    case '':
      rwIconComponent = <VscBlank {...rest} />
      break
    case 'SiQuickbooks':
      rwIconComponent = <SiQuickbooks {...rest} />
      break
    case 'IoSearchCircleOutline':
      rwIconComponent = <IoSearchCircleOutline {...rest} />
      break
    case 'ImCheckmark':
      rwIconComponent = <ImCheckmark {...rest} />
      break
    case 'AiOutlineFilePdf':
      rwIconComponent = <AiOutlineFilePdf {...rest} />
      break
    case 'BiCheckbox':
      rwIconComponent = <BiCheckbox {...rest} />
      break
    case 'BiCheckboxChecked':
      rwIconComponent = <BiCheckboxChecked {...rest} />
      break
    case 'BsCardChecklist':
      rwIconComponent = <BsCardChecklist {...rest} />
      break
    case 'BsCalendar3':
      rwIconComponent = <BsCalendar3 {...rest} />
      break
    case 'BsGraphUpArrow':
      rwIconComponent = <BsGraphUpArrow {...rest} />
      break
    case 'BsBox2Fill':
      rwIconComponent = <BsBox2Fill {...rest} />
      break
    case 'BsFillPeopleFill':
      rwIconComponent = <BsFillPeopleFill {...rest} />
      break
    case 'BsFileRuled':
      rwIconComponent = <BsFileRuled {...rest} />
      break
    case 'BsFillGrid3X3GapFill':
      rwIconComponent = <BsFillGrid3X3GapFill {...rest} />
      break
    case 'Tag':
      rwIconComponent = <BsTag {...rest} />
      break
    case 'Info':
        rwIconComponent = <FaInfoCircle {...rest} />
        break
    case 'Tags':
      rwIconComponent = <BsTags {...rest} />
      break
    case 'Youtube':
      rwIconComponent = <FaYoutube {...rest} />
      break
    case 'Pdf':
      rwIconComponent = <BsFileEarmarkPdf {...rest} />
      break
    case 'Excel':
      rwIconComponent = <BsFileEarmarkExcel {...rest} />
      break
    case 'Barcode':
      rwIconComponent = <FaBarcode {...rest} />
      break
    case 'Hamburger':
      rwIconComponent = <FaBars {...rest} />
      break
    case 'Truck':
      rwIconComponent = <FaTruck {...rest} />
      break
    case 'ExternalLink':
      rwIconComponent = <BsFillArrowUpRightSquareFill {...rest} />
      break
    case 'Tools':
      rwIconComponent = <FaTools {...rest} />
      break
    case 'Bookmark':
      rwIconComponent = <BsFillBookmarksFill {...rest} />
      break
    case 'Lock':
      rwIconComponent = <FaLock {...rest} />
      break
    case 'LockOpen': 
      rwIconComponent = <FaLockOpen {...rest} />
      break
    case 'AddressCard':
      rwIconComponent = <FaAddressCard {...rest} />
      break
    case 'Key':
      rwIconComponent = <FaKey {...rest} />
      break
    case 'Email':
      rwIconComponent = <MdEmail {...rest} />
      break
    case 'Preferences':
      rwIconComponent = <MdMarkEmailRead {...rest} />
      break
    case 'Video':
      rwIconComponent = <MdOutlineOndemandVideo {...rest} />
      break
    case 'Rush':
      rwIconComponent = <MdSpeed {...rest} />
      break
    case 'Document':
      rwIconComponent = <RiFileTextFill {...rest} />
      break
    case 'SignOut':
      rwIconComponent = <FaSignOutAlt {...rest} />
      break
    case 'User':
      rwIconComponent = <FaUser {...rest} />
      break
    case 'Remove':
      rwIconComponent = <FaRegMinusSquare {...rest} />
      break
    case 'Download':
      rwIconComponent = <FaArrowCircleDown {...rest} />
      break
    case 'CircleXMark':
      rwIconComponent = <BsXCircleFill {...rest} />
      break
    case 'Eye':
      rwIconComponent = <BsEye {...rest} />
      break
    case 'EyeHide':
      rwIconComponent = <BsEyeSlash {...rest} />
      break
    case 'Minus':
      rwIconComponent = <FaMinus {...rest} />
      break
    case 'Plus':
      rwIconComponent = <FaPlus {...rest} />
      break
    case 'Search':
      rwIconComponent = <FaSearch {...rest} />
      break
    case 'Warning': // triangle
      rwIconComponent = <IoWarningOutline {...rest} />
      break
    case 'EyeIcon':
      rwIconComponent = <BsEye {...rest} />
      break
    case 'EyeIconHide':
      rwIconComponent = <BsEyeSlash {...rest} />
      break
    case '76':
      rwIconComponent = (
        <CdnImg
          path={`_images/icons/76s-02.png`}
          className={`${cssClassName} p-0`}
          height="20px"
          alt="76 logo"
        />
      )
      break
    case '76Dm':
      rwIconComponent = (
        <CdnImg
          path={`_images/76s-03-red.png`}
          className={`${cssClassName} p-0`}
          height="20px"
          alt="76 logo"
        />
      )
      break
    case 'Ups':
      rwIconComponent = (
        <CdnImg
          path={`_images/icons/icon-UPS.gif`}
          className="p-0"
          height="20px"
          alt="ups logo"
        />
      )
      break
    case 'TForce':
      rwIconComponent = (
        <CdnImg
          path={`_images/icons/icon_TForce.gif`}
          className="p-0"
          width="45px"
          alt="tforce logo"
        />
      )
      break
    case 'Eport':
      rwIconComponent = (
        <CdnImg
          path={`_images/ignition/eport.png`}
          className="p-0"
          width="45px"
          alt="eport logo"
        />
      )
      break

    case 'Tlock':
      rwIconComponent = (
        <CdnImg
          path={`_images/ignition/tlock.png`}
          className="p-0"
          width="45px"
          alt="tlock logo"
        />
      )
      break
    case 'DeadFish':
      rwIconComponent = <GiFishbone {...rest} />
      break
    default:
      rwIconComponent = <></>
      break
  }

  return <>{rwIconComponent}</>
}

export default RwIcon
