import React, { ReactNode } from 'react'
import { Form } from 'react-bootstrap'

export interface RwDisplayCheckboxProps {
  label: string | ReactNode
  value?: boolean
  className?: string
}

const RwDisplayCheckbox: React.FC<RwDisplayCheckboxProps> = (
  props: RwDisplayCheckboxProps,
) => {
  const {
    label,
    value,
    className = '',
    ...rest
  } = props

  const isChecked = value ? true : false;

  return (
    <div className={className}>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          className="mb-3 me-2"
          disabled
        />
        {label}
      </label>
    </div>
  );
}

export default RwDisplayCheckbox
