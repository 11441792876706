import * as Yup from 'yup'

const numberSchema = Yup.number().typeError('Must be a number')

export const DEFAULT_TAB = 'dashboard'
export const TAB_BACK_PATH = '/products/editor'

// we will add fields here as we continue to add tabs
export const validationSchema = Yup.object().shape(
  {
    location_pick_select: Yup.boolean(),
    location_storage: Yup.boolean(),
    location_zone_pick: Yup.boolean(),
    location_small_parcel_pick_select: Yup.boolean(),
    location_small_parcel_piece: Yup.boolean(),
    location_oversized_storage: Yup.boolean(),
    location_storage_4_deep: Yup.boolean(),
    location_bulk_storage: Yup.boolean(),
    is_level_one_only: Yup.boolean(),
    location_level_4_allowed: Yup.boolean(),
    hazard_class_1_3: Yup.boolean(),
    products_length: numberSchema.nullable(),
    products_width: numberSchema.nullable(),
    products_height: numberSchema.nullable(),
    pack_length: numberSchema.nullable(),
    pack_width: numberSchema.nullable(),
    pack_height: numberSchema.nullable(),
    case_weight: Yup.number()
      .typeError('Products Weight must be a number')
      .nullable(),
    case_length: Yup.number()
      .typeError('Case Length must be a number')
      .nullable(),
    case_width: Yup.number()
      .typeError('Case Width must be a number')
      .nullable(),
    case_height: Yup.number()
      .typeError('Case Height must be a number')
      .nullable(),
    factory_code: Yup.string(),
    products_cbm: Yup.number().typeError('CBM must be a number').nullable(),
    products_cases_per_layer: Yup.number()
      .typeError('Case Weight be a number')
      .nullable(),
    layers_per_pallet: Yup.number()
      .typeError('Layers per Pallet must be a number')
      .nullable(),
    min_main_storage_pallets: Yup.number()
      .typeError('Minimum Pallets must be a number')
      .nullable(),
    max_main_storage_pallets: Yup.number()
      .typeError('Maximum Pallets must be a number')
      .nullable(),
    overflow_cases_per_pallet: Yup.number()
      .typeError('Overflow Cases must be a number')
      .nullable(),
    products_storage_type: Yup.string(),
    is_top_stack: Yup.boolean(),
    products_parcel_ship_separate: Yup.boolean(),
    products_usps_ship: Yup.boolean(),
    products_parcel_ship: Yup.boolean(),
    products_parcel_ship_air: Yup.boolean(),
    products_parcel_ship_fedex_ground: Yup.boolean(),
    products_parcel_ship_flatrate: Yup.boolean(),
    products_id: Yup.number().required('Products ID is Required'),
    products_model: Yup.string().required('Products Model is Required'),
    products_name: Yup.string().required('Products Name is Required'),
    products_packaging: Yup.string(),
    products_manf_model: Yup.string(),
    products_ex_number: Yup.string(),
    products_duration: Yup.number().typeError(
      'Products Duration should be a number',
    ),
    products_shots: Yup.number().typeError('Products Shots should be a number'),
    products_notes: Yup.string(),
    manufacturers_id: Yup.number().required('Products Brand is Required'),
    suppliers_id: Yup.number().required('Products Supplier is Required'),
    products_un_number: Yup.string().required('Products UN Number is Required'),
    products_type: Yup.string(),
    products_ignition: Yup.string(),
    products_status: Yup.number().required('Products Status is Required'),
    export_to_cobra: Yup.boolean(),
    export_to_finale: Yup.boolean(),
    performance_prefire_time: Yup.number().typeError(
      'Safety Distance should be a number',
    ),
    performance_height: Yup.number().typeError(
      'Performance Height should be a number',
    ),
    products_year_added: Yup.number(),
    products_price_no_discount: Yup.boolean(),
    products_url: Yup.string(),
    auto_generated_products_url: Yup.string(),
    calibers: Yup.array().of(
      Yup.object().shape({
        caliber_value: Yup.number()
          .typeError('Caliber should be a number')
          .required('Caliber is Required'),
        caliber_units: Yup.string().required('Caliber Units are Required'),
      }),
    ),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required('Required'),
          categories_id: Yup.number().required('Required'),
          is_primary: Yup.boolean(),
        }),
      )
      .test(
        'exactly-one-primary',
        'Exactly one primary category must be selected',
        (categories) => {
          // If there are no categories, validation passes
          if (!categories || categories.length === 0) return true

          // Filter categories to find those marked as primary
          const primaryCategories = categories.filter(
            (category) => category.is_primary,
          )

          // Validate exactly one primary category
          return primaryCategories.length === 1
        },
      ),
    related_products: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required('Required'),
        related_products_model: Yup.string().required(
          'Related Products Model is Required',
        ),
        related_products_id: Yup.number(),
        related_products_name: Yup.string(),
      }),
    ),
    location_types: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required('Required'),
        location_type_name: Yup.string(),
        location_type_id: Yup.number().required('Location Type is Required'),
      }),
    ),
    case_has_packs: Yup.boolean(),
    case_has_pieces: Yup.boolean(),
    pieces_per_case: Yup.number().when('case_has_pieces', {
      is: true,
      then: Yup.number().required(
        'Pieces Per Case required when Case Has Pieces is checked',
      ),
    }),
    pieces_per_pack: Yup.number().when('pack_has_pieces', {
      is: true,
      then: Yup.number().required(
        'Pieces per Pack required when Pack Has Pieces is checked',
      ),
    }),
    pack_has_pieces: Yup.boolean(),
    packs_per_case: Yup.number().when('case_has_packs', {
      is: true,
      then: Yup.number().required(
        'Packs per Case required when Case Has Packs is checked',
      ),
    }),
    has_mixed_pieces: Yup.boolean(),
    retail_description: Yup.string(),
    products_description: Yup.string(),
    proline_description: Yup.string(),
    net_explosive_quantity: Yup.number(),
    is_outdoor_use_only: Yup.boolean(),
    safety_distance: Yup.number().typeError(
      'Safety Distance should be a number',
    ),
    has_performance_attributes: Yup.boolean(),
    is_on_wholesale_site: Yup.boolean(),
    is_on_proline_site: Yup.boolean(),
    list_just_arrived: Yup.boolean(),
    list_just_arrived_expire_date: Yup.date().when('list_just_arrived', {
      is: true,
      then: Yup.date().required(
        'Expiration date is required when Just Arrived is selected',
      ),
      otherwise: Yup.date(),
    }),
    f3d_type: Yup.string(),
    f3d_subtype: Yup.string(),
    cobra_type: Yup.string(),
    products_vdl: Yup.string(),
    products_short_description: Yup.string(),
    // has_spanish_label: Yup.boolean(),
    // products_list_patriotic: Yup.boolean(),
    // products_list_low_noise: Yup.boolean(),
    new_product: Yup.boolean(),
    // products_list_staff_fav: Yup.boolean(),
    // products_list_customer_fav: Yup.boolean(),
    // products_list_best_sellers: Yup.boolean(),
    products_upc_case: Yup.string(),
    products_upc_pk: Yup.string(),
    products_upc_pc: Yup.string(),
    is_active_pricing: Yup.boolean(),
    pricing_category_id: Yup.string(),
  },
  [],
)
