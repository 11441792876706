import React from 'react'
import { stringToBoolean } from '../../../helpers'
import { BsCheck, BsX } from 'react-icons/bs'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IDisplayCheck {
  value: boolean | string
  className?: string
  showFail?: boolean
  [x: string]: any
}

const DisplayCheck: React.FC<IDisplayCheck> = ({
  value,
  className,
  showFail = false,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()
  const boolValue = typeof value == 'string' ? stringToBoolean(value) : value

  return (
    <>
      {boolValue ? (
        <BsCheck
          style={{ fontSize: '30px' }}
          className={`${isDarkMode ? '' : 'text-success'} ${className}`}
        />
      ) : showFail ? (
        <BsX
          style={{ fontSize: '30px' }}
          className={`${isDarkMode ? '' : 'text-accent'} ${className}`}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default DisplayCheck
