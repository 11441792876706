import React from 'react'
import { ListGroup, OverlayTrigger } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'

interface IRowContextMenu {
  [x: string]: any
  rootClose?: boolean
  isModal?: boolean
}

const RowContextMenu: React.FC<IRowContextMenu> = ({
  children,
  rootClose = false,
  isModal = false,
}) => {
  const renderMenu = (props: any) => (
    <div
      {...props}
      className={`${isModal ? 'modal-overlay' : ''} border rounded ms-1 me-3`}
    >
      <ListGroup>{children}</ListGroup>
    </div>
  )
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="left-start"
        overlay={renderMenu}
        rootClose={rootClose}
      >
        <div className="px-2">
          <BsThreeDotsVertical className="fs-4" />
        </div>
      </OverlayTrigger>
    </>
  )
}

export default RowContextMenu
