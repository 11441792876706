import React, { ReactNode } from 'react'
import { Container, Navbar } from 'react-bootstrap'

export interface IRwNavBar {
  title?: string
  expand?: string | boolean
  [x: string]: any
}

const RwNavBar: React.FC<IRwNavBar> = ({
  children,
  title = null,
  expand = true,
  ...rest
}) => {
  return (
    <div className="border-bottom" {...rest}>
      <Navbar expand={expand}>
        <Container fluid className="">
          {title && <Navbar.Brand className="">{title}</Navbar.Brand>}
          {children ? (
            <>
              {/* <div className="pb-1"> */}
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              {/* </div> */}
              <Navbar.Collapse>{children}</Navbar.Collapse>{' '}
            </>
          ) : (
            <></>
          )}
        </Container>
      </Navbar>
    </div>
  )
}

export default RwNavBar
