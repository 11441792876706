import { createContext, useReducer, useContext } from 'react'
import {
  GET_CUSTOM_ASSORTMENTS,
  GET_ASSORTMENT_COMPONENTS,
  SEARCH_PRODUCTS_FOR_ASSORTMENTS,
  GET_ASSORTMENT_DETAILS,
  GET_ASSORTMENT_GHOST_ORDER,
  GET_DEAL_WITH_PRODUCTS,
  GET_ACTIVE_PRICE_LEVELS,
  GET_PRODUCT,
  GET_PRODUCT_COLORS,
  GET_PRODUCT_EFFECTS,
  GET_PRODUCT_FIRING_PATTERNS,
  GET_PRODUCT_DEBRIS_PROFILES,
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_SUPPLIERS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCTS_LIST,
} from './constants'
import {
  IProductState,
  IAssortmentComponentData,
  IAddComponentToAssortmentProps,
  IGetCustomAssortmentGhostOrderProps,
  IProductData,
  IProductListProductFilter,
} from './types'
import productReducer from './ProductReducer'
import { IContextProviderProps } from '../../types/interfaces'
import { API_PRODUCTS_URL } from '../apiconfig'
import { api } from '../../helpers/Api'
import toast from '../../components/molecules/RwToast'
import axios from 'axios'
import { IProductImage, IProductVideo } from './types'
import { IProductsList } from '../../queries/products/types'

export const initialState: IProductState = {
  product: null,
  productColors: [],
  productBrands: [],
  productSuppliers: [],
  productCategories: [],
  productEffects: [],
  productFiringPatterns: [],
  productDebrisProfiles: [],
  assortmentProductsSearchResults: [],
  componentsForAssortment: [],
  customAssortmentsList: [],
  customAssortmentDetails: null,
  customAssortmentGhostOrder: null,
  productsDeal: null,
  dealProducts: [],
  activePriceLevels: [],
  productsList: null,
  getProduct: () => {},
  getProductBrands: () => {},
  getProductSuppliers: () => {},
  getProductCategories: () => {},
  getProductColors: () => {},
  getProductEffects: () => {},
  uploadProductImage: () => {},
  addProductVideo: () => {},
  editProductImage: () => {},
  editProductVideo: () => {},
  getProductFiringPatterns: () => {},
  getProductDebrisProfiles: () => {},
  clearProductContext: () => {},
  updateProduct: () => {},
  getCustomAssortmentsList: () => {},
  cleanupCustomAssortmentsListData: () => {},
  getComponentsForAssortment: () => {},
  searchActiveProducts: () => {},
  addComponentToAssortment: () => {},
  getCustomAssortmentDetails: () => {},
  updateAssortmentComponent: () => {},
  getCustomAssortmentGhostOrder: () => {},
  cleanupCustomAssortmentData: () => {},
  getDealWithProducts: () => {},
  cleanupDealContext: () => {},
  getActivePriceLevels: () => {},
  cleanupPriceLevelsContext: () => {},
  addProductsToDeal: () => {},
  removeProductsFromDeal: () => {},
  downloadProductSpreadsheetTemplate: () => {},
  downloadOpenOrdersProductReport: () => {},
  submitProductSpreadsheet: () => {},
  updateProductList: () => {},
  clearProductsListContext: () => {},
  getProductsListDetail: () => {},
  addProductToProductList: () => {},
  removeProductFromProductList: () => {},
  saveProductList: () => {},
}

export const ProductContext = createContext<IProductState>(initialState)

export const useProductContext = () => useContext(ProductContext)

export const ProductProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props
  const [state, dispatch] = useReducer(productReducer, initialState)

  const getProduct = async (products_id: string) => {
    const response = await api.get(`${API_PRODUCTS_URL}${products_id}`)

    dispatch({
      type: GET_PRODUCT,
      payload: response.data,
    })
  }

  const clearProductContext = async () => {
    dispatch({
      type: GET_PRODUCT,
      payload: null,
    })
    dispatch({
      type: GET_PRODUCT_COLORS,
      payload: null,
    })
    dispatch({
      type: GET_PRODUCT_EFFECTS,
      payload: null,
    })
    dispatch({
      type: GET_PRODUCT_FIRING_PATTERNS,
      payload: null,
    })
    dispatch({
      type: GET_PRODUCT_DEBRIS_PROFILES,
      payload: null,
    })
    dispatch({
      type: GET_PRODUCTS_LIST,
      payload: null,
    })
  }

  const addProductVideo = async (
    productVideoData: IProductVideo,
    onComplete: () => void,
  ) => {
    const response = await api.post(`${API_PRODUCTS_URL}add-product-video`, {
      productVideoData,
    })
    if (!response?.data) {
      toast.error(`${'An error occurred.'}`)
      onComplete()
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(
          `${response?.data?.message || 'Video added successfully.'}`,
        )
      }
      onComplete()
    }
  }

  const editProductVideo = async (
    productVideoData: IProductVideo,
    onComplete: () => void,
  ) => {
    const response = await api.put(`${API_PRODUCTS_URL}edit-product-video`, {
      productVideoData,
    })
    if (!response?.data) {
      toast.error(`${'An error occurred.'}`)
      onComplete()
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(
          `${response?.data?.message || 'Video updated successfully.'}`,
        )
      }
      onComplete()
    }
  }

  const uploadProductImage = async (
    productImageData: IProductImage,
    onComplete: () => void,
  ) => {
    const response = await api.post(`${API_PRODUCTS_URL}upload-product-image`, {
      productImageData,
    })
    if (!response?.data) {
      toast.error(`${'An error occurred.'}`)
      onComplete()
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(
          `${response?.data?.message || 'Image added successfully.'}`,
        )
      }
      onComplete()
    }
  }

  const editProductImage = async (
    productImageData: IProductImage,
    onComplete: () => void,
    products_id: string,
  ) => {
    const response = await api.put(`${API_PRODUCTS_URL}edit-product-image`, {
      productImageData,
      products_id,
    })
    if (!response?.data) {
      toast.error(`${'An error occurred.'}`)
      onComplete()
    } else {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(
          `${response?.data?.message || 'Image updated successfully.'}`,
        )
      }
      onComplete()
    }
  }

  const getProductColors = async () => {
    const response = await api.get(`${API_PRODUCTS_URL}get-product-colors`)

    dispatch({
      type: GET_PRODUCT_COLORS,
      payload: response.data,
    })
  }

  const getProductEffects = async () => {
    const response = await api.get(`${API_PRODUCTS_URL}get-product-effects`)

    dispatch({
      type: GET_PRODUCT_EFFECTS,
      payload: response.data,
    })
  }

  const getProductFiringPatterns = async () => {
    const response = await api.get(
      `${API_PRODUCTS_URL}get-product-firing-patterns`,
    )

    dispatch({
      type: GET_PRODUCT_FIRING_PATTERNS,
      payload: response.data,
    })
  }

  const getProductDebrisProfiles = async () => {
    const response = await api.get(
      `${API_PRODUCTS_URL}get-product-debris-profiles`,
    )

    dispatch({
      type: GET_PRODUCT_DEBRIS_PROFILES,
      payload: response.data,
    })
  }

  const getProductSuppliers = async () => {
    const response = await api.get(`${API_PRODUCTS_URL}get-suppliers`)
    dispatch({
      type: GET_PRODUCT_SUPPLIERS,
      payload: response.data,
    })
  }

  const getProductBrands = async () => {
    const response = await api.get(`${API_PRODUCTS_URL}get-brands`)
    dispatch({
      type: GET_PRODUCT_BRANDS,
      payload: response.data,
    })
  }

  const getProductCategories = async () => {
    const response = await api.get(`${API_PRODUCTS_URL}get-categories`)
    dispatch({
      type: GET_PRODUCT_CATEGORIES,
      payload: response.data,
    })
  }

  const updateProduct = async (product: IProductData) => {
    const response = await api.post(`${API_PRODUCTS_URL}save`, product)

    if (response?.data) {
      if (response.data.errors) {
        response.data.errors.forEach((error: string) => {
          toast.error(error)
        })
      } else {
        toast.success(`${response?.data?.message || 'Updated.'}`)
        dispatch({
          type: GET_PRODUCT,
          payload: response?.data,
        })
      }
    }
  }

  const getCustomAssortmentsList = async () => {
    const response = await api.get(`${API_PRODUCTS_URL}custom-assortments`)

    if (response?.data?.data?.length > 0) {
      dispatch({
        type: GET_CUSTOM_ASSORTMENTS,
        payload: response.data.data,
      })
    } else {
      toast.info(response?.data?.message)
    }
  }

  const cleanupCustomAssortmentsListData = () => {
    dispatch({
      type: GET_CUSTOM_ASSORTMENTS,
      payload: [],
    })
  }

  const getComponentsForAssortment = async (
    caseId: string | null,
    version: string | null,
  ) => {
    if (!caseId || !version) {
      dispatch({
        type: GET_ASSORTMENT_COMPONENTS,
        payload: [],
      })
    } else {
      const response = await api.get(
        `${API_PRODUCTS_URL}get-assortment-components?case_id=${caseId}&version_id=${version}`,
      )
      dispatch({
        type: GET_ASSORTMENT_COMPONENTS,
        payload: response.data.data,
      })
    }
  }

  const getCustomAssortmentDetails = async (
    caseId: string | null,
    version: string | null,
  ) => {
    if (!caseId || !version) {
      dispatch({
        type: GET_ASSORTMENT_DETAILS,
        payload: null,
      })
      return
    }

    const response = await api.get(
      `${API_PRODUCTS_URL}get-assortment-details?case_id=${caseId}&version_id=${version}`,
    )
    dispatch({
      type: GET_ASSORTMENT_DETAILS,
      payload: response.data.data,
    })

    let products_id = response.data.data.assortment_products_id
    getCustomAssortmentGhostOrder({
      products_id,
      caseId,
      version,
    })
  }

  const searchActiveProducts = async (searchTerm: string) => {
    if (searchTerm === '' || searchTerm === null) {
      dispatch({
        type: SEARCH_PRODUCTS_FOR_ASSORTMENTS,
        payload: [],
      })
    } else {
      const response = await api.get(
        `${API_PRODUCTS_URL}search-products?searchTerm=${searchTerm}`,
      )
      dispatch({
        type: SEARCH_PRODUCTS_FOR_ASSORTMENTS,
        payload: response.data.data,
      })
    }
  }

  const addComponentToAssortment = async (
    props: IAddComponentToAssortmentProps,
  ) => {
    const { assortmentCaseId, assortmentVersion } = props
    const response = await api.post(
      `${API_PRODUCTS_URL}add-assortment-component`,
      { ...props },
    )

    if (response.data.success) {
      toast.success(response.data.message)
      getComponentsForAssortment(assortmentCaseId, assortmentVersion)
    } else {
      toast.error('Error adding product to assortment')
    }
  }

  const updateAssortmentComponent = async (
    component: IAssortmentComponentData,
  ) => {
    const response = await api.post(
      `${API_PRODUCTS_URL}update-assortment-component`,
      { component },
    )

    if (response.data.success) {
      toast.success(response.data.message)
      getComponentsForAssortment(
        component.parent_case_id,
        component.assortment_version_id,
      )
    } else {
      toast.error('Error updating assortment component')
    }
  }

  const getCustomAssortmentGhostOrder = async (
    props: IGetCustomAssortmentGhostOrderProps,
  ) => {
    const { products_id, caseId, version } = props
    const response = await api.get(
      `${API_PRODUCTS_URL}get-assortment-ghost-order?products_id=${products_id}&case_id=${caseId}&version_id=${version}`,
    )

    if (response.data.has_ghost_order) {
      dispatch({
        type: GET_ASSORTMENT_GHOST_ORDER,
        payload: response.data.order,
      })
    }
  }

  const cleanupCustomAssortmentData = () => {
    dispatch({
      type: GET_ASSORTMENT_COMPONENTS,
      payload: [],
    })

    dispatch({
      type: GET_ASSORTMENT_DETAILS,
      payload: null,
    })

    dispatch({
      type: GET_ASSORTMENT_GHOST_ORDER,
      payload: null,
    })
  }

  const getDealWithProducts = async (dealId: string) => {
    const response = await api.get(`${API_PRODUCTS_URL}deal?deal_id=${dealId}`)

    if (response.data.success) {
      dispatch({
        type: GET_DEAL_WITH_PRODUCTS,
        payload: { deal: response.data.deal, products: response.data.products },
      })
    } else {
      toast.error('There was a problem fetching the deal details.')
    }
  }

  const cleanupDealContext = () => {
    dispatch({
      type: GET_DEAL_WITH_PRODUCTS,
      payload: { deal: null, products: [] },
    })
  }

  const getActivePriceLevels = async () => {
    const response = await api.get(`${API_PRODUCTS_URL}get-active-price-levels`)

    if (response.data.success) {
      dispatch({
        type: GET_ACTIVE_PRICE_LEVELS,
        payload: response.data.data,
      })
    }
  }

  const cleanupPriceLevelsContext = () => {
    dispatch({
      type: GET_ACTIVE_PRICE_LEVELS,
      payload: [],
    })
  }

  const addProductsToDeal = async (productsIds: string[], dealId: string) => {
    const response = await api.post(`${API_PRODUCTS_URL}add-products-to-deal`, {
      productsIds,
      dealId,
    })

    if (response.data.success) {
      getDealWithProducts(dealId)
      toast.success(response.data.message)
    } else {
      getDealWithProducts(dealId)
      toast.error(response.data.message)
    }
  }

  const removeProductsFromDeal = async (
    productsIds: string[],
    dealId: string,
  ) => {
    const response = await api.post(
      `${API_PRODUCTS_URL}remove-products-from-deal`,
      { productsIds, dealId },
    )

    if (response.data.success) {
      toast.success(response.data.message)
      getDealWithProducts(dealId)
    }
  }

  const downloadOpenOrdersProductReport = async (
    productsId: string | undefined,
    productsModel: string | undefined,
  ) => {
    try {
      const response = await axios.get(
        `${API_PRODUCTS_URL}open-orders-report/${productsId}`,
        { responseType: 'blob' },
      )

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `open_orders_product_report_${productsModel}_${new Date()
        .toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\//g, '-')}.xlsx`
      link.click()
    } catch (error) {
      console.error('Error downloading open orders product report:', error)
    }
  }

  const downloadProductSpreadsheetTemplate = async (sheetType: string) => {
    const response = await axios.get(
      `${API_PRODUCTS_URL}spreadsheet-template/${sheetType}`,
      { responseType: 'arraybuffer' },
    )

    if (response.data) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const link = document.createElement('a')
      const filename = response.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]+/g, '')

      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      toast.error(response.data.message)
    }
  }

  const submitProductSpreadsheet = async (formData: FormData) => {
    const response = await api.post(
      `${API_PRODUCTS_URL}upload-spreadsheet`,
      formData,
    )

    if (response.data.success) {
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message)
    }

    return response.data
  }

  const updateProductList = async (
    list: IProductsList,
    update_context: boolean = false,
  ) => {
    const response = await api.post(
      `${API_PRODUCTS_URL}site-products-lists/update`,
      {
        list,
      },
    )

    if (response.data.success) {
      toast.success(response.data.message)
      if (update_context) {
        dispatch({
          type: GET_PRODUCTS_LIST,
          payload: response.data.data,
        })
      }
    } else {
      toast.error(response.data.message || 'Error updating product list')
    }
  }

  const clearProductsListContext = () => {
    dispatch({
      type: GET_PRODUCTS_LIST,
      payload: null,
    })
  }

  const getProductsListDetail = async (
    id: string,
    searchParams: IProductListProductFilter,
  ) => {
    const response = await api.post(
      `${API_PRODUCTS_URL}site-products-lists/detail`,
      { id, searchParams },
    )

    if (response.data.success) {
      dispatch({
        type: GET_PRODUCTS_LIST,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message)
    }
  }

  const addProductToProductList = async (
    products_id: string,
    list_id: string,
    search_params: IProductListProductFilter,
  ) => {
    const response = await api.post(
      `${API_PRODUCTS_URL}site-products-lists/add-product`,
      { products_id, list_id, search_params },
    )

    if (response.data.success) {
      toast.success(response.data.message || 'Success')
      dispatch({
        type: GET_PRODUCTS_LIST,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message || 'Sorry, something went wrong')
    }
  }

  const removeProductFromProductList = async (
    products_id: string,
    list_id: string,
    search_params: IProductListProductFilter,
  ) => {
    const response = await api.post(
      `${API_PRODUCTS_URL}site-products-lists/remove-product`,
      { products_id, list_id, search_params },
    )

    if (response.data.success) {
      toast.success(response.data.message || 'Success')
      dispatch({
        type: GET_PRODUCTS_LIST,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message || 'Sorry, something went wrong')
    }
  }

  const saveProductList = async (list: IProductsList) => {
    const action = list.id ? 'update' : 'create'
    const response = await api.post(
      `${API_PRODUCTS_URL}site-products-lists/${action}`,
      {
        list,
      },
    )

    if (response.data.success) {
      toast.success(response.data.message)
      dispatch({
        type: GET_PRODUCTS_LIST,
        payload: response.data.data,
      })
    } else {
      toast.error(response.data.message || 'Sorry, something went wrong')
    }
  }

  const value = {
    ...state,
    getProduct,
    getProductColors,
    getProductBrands,
    getProductEffects,
    getProductFiringPatterns,
    getProductDebrisProfiles,
    getProductSuppliers,
    getProductCategories,
    clearProductContext,
    updateProduct,
    getCustomAssortmentsList,
    cleanupCustomAssortmentsListData,
    getComponentsForAssortment,
    searchActiveProducts,
    addComponentToAssortment,
    getCustomAssortmentDetails,
    updateAssortmentComponent,
    getCustomAssortmentGhostOrder,
    cleanupCustomAssortmentData,
    addProductVideo,
    uploadProductImage,
    editProductImage,
    editProductVideo,
    getDealWithProducts,
    cleanupDealContext,
    getActivePriceLevels,
    cleanupPriceLevelsContext,
    addProductsToDeal,
    removeProductsFromDeal,
    downloadProductSpreadsheetTemplate,
    downloadOpenOrdersProductReport,
    submitProductSpreadsheet,
    updateProductList,
    clearProductsListContext,
    getProductsListDetail,
    addProductToProductList,
    removeProductFromProductList,
    saveProductList,
  }

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  )
}
