import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useProductContext } from '../../../context/products/ProductProvider'
import toast from '../../molecules/RwToast'
import AssortmentComponentsList from '../../organisms/CustomAssortment/AssortmentComponentsList'
import AssortmentGhostOrderCard from '../../organisms/CustomAssortment/AssortmentGhostOrderCard'
import AssortmentDetailsForm from '../../organisms/CustomAssortment/AssortmentDetailsForm'
import ProductSearch from '../../organisms/Product/ProductSearch'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'

export const CustomAssortmentBreadcrumb: React.FC = () => {
  const { customAssortmentDetails } = useProductContext()
  return (
    <span>
      {customAssortmentDetails?.assortment_products_model
        ? customAssortmentDetails?.assortment_products_model
        : 'New Assortment'}
    </span>
  )
}

const CustomAssortment: React.FC = () => {
  const { case_id, version } = useParams()
  const [caseIdState, setCaseIdState] = useState<string | null>(null)
  const [versionState, setVersionState] = useState<string | null>(null)
  const {
    getComponentsForAssortment,
    getCustomAssortmentDetails,
    addComponentToAssortment,
    cleanupCustomAssortmentData,
    customAssortmentDetails,
    componentsForAssortment,
  } = useProductContext()

  const navigate = useNavigate()

  const updateDeny =
    'Cannot update quantities, add or remove components on a reserve stock assortment that is on an order. Please clone or create a new assortment.'

  useEffect(() => {
    return () => {
      cleanupCustomAssortmentData()
    }
  }, [])

  useEffect(() => {
    if (case_id && version) {
      setCaseIdState(case_id)
      setVersionState(version)
    }
  }, [case_id, version])

  useEffect(() => {
    getCustomAssortmentDetails(caseIdState, versionState)
    getComponentsForAssortment(caseIdState, versionState)
  }, [caseIdState, versionState])

  const handleClose = () => {
    navigate(-1)
  }

  const handleAddToAssortmentClick = (productsId: string) => {
    if (
      !(
        customAssortmentDetails?.is_on_order &&
        customAssortmentDetails?.ghost_order_id
      )
    ) {
      addComponentToAssortment({
        productsId: productsId,
        assortmentCaseId: caseIdState,
        assortmentVersion: versionState,
        ghostOrderId: customAssortmentDetails?.ghost_order_id || null,
      })
    } else {
      toast.error(updateDeny)
    }
  }

  return (
    <>
      <RwNavBar>
        <RwNavItem variant="close" onClick={handleClose} />
      </RwNavBar>

      <Container fluid>
        <Row>
          <div className="col-xxl-3 col-xl-6 col-lg-12 order-xxl-1 order-xl-1">
            <ProductSearch
              disabledProducts={componentsForAssortment}
              handleButtonClick={handleAddToAssortmentClick}
              handleRowDoubleClick={handleAddToAssortmentClick}
              maxHeight="80vh"
            />
            {/* <AssortmentComponentSearch
            handleAddButtonClick={handleAddToAssortmentClick}
          /> */}
          </div>
          <div className="col-xxl-6 col-xl-12 col-lg-12 order-xxl-2 order-xl-3">
            <AssortmentComponentsList />
            <br />
            <AssortmentGhostOrderCard
              caseId={caseIdState}
              version={versionState}
              handleAddButtonClick={handleAddToAssortmentClick}
            />
          </div>
          <div className="col-xxl-3 col-xl-6 col-lg-12 order-xxl-3 order-xl-2">
            <AssortmentDetailsForm
              caseId={caseIdState}
              version={versionState}
            />
          </div>
        </Row>
      </Container>
    </>
  )
}

export default CustomAssortment
