import React, { useEffect, useRef, useState } from 'react'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { Editor } from '@tinymce/tinymce-react'
import { initiallySelectedAppSettings } from '../../../constants/constants'
import { useUserContext } from '../../../context/user/UserProvider'
import { INoteData } from '../../../context/site/types'
import RwButton from '../../molecules/RwButton/RwButton'

interface INotesFieldProps {
  saveNotes?: (notes: string) => void
  updateNote?: (note: INoteData) => void
  noteObj?: INoteData
  toggleEdit?: (val: boolean) => void
}

const NotesField: React.FC<INotesFieldProps> = ({
  saveNotes = null,
  updateNote = null,
  noteObj,
  toggleEdit = null,
}) => {
  const { getAppSettings, appSettings } = useSiteContext()
  const editorRef = useRef<any>(null)
  const [notes, setNotes] = useState<string>('')
  const { isDarkMode } = useUserContext()

  useEffect(() => {
    getAppSettings(initiallySelectedAppSettings)
  }, [])

  useEffect(() => {
    if (noteObj) {
      setNotes(noteObj.note)
      if (editorRef.current) {
        editorRef.current.setContent(noteObj.note)
        editorRef.current.focus()
      }
    }
  }, [noteObj, editorRef.current])

  const handleSubmit = () => {
    if (saveNotes) {
      saveNotes(notes)
    }
    if (editorRef.current) {
      editorRef.current.setContent('')
      editorRef.current.focus()
    }
  }

  const handleUpdateNote = (noteObj: INoteData) => {
    toggleEdit && toggleEdit(false)
    if (updateNote) {
      updateNote({
        ...noteObj,
        note: notes,
      })
    }
  }

  return (
    <>
      <Editor
        apiKey={appSettings?.tiny_key}
        onInit={(_evt, editor) => (editorRef.current = editor)}
        init={{
          branding: false,
          skin: isDarkMode ? 'oxide-dark' : 'oxide',
          content_css: isDarkMode ? 'dark' : 'default',
          plugins:
            'anchor autolink charmap codesample emoticons link lists searchreplace table visualblocks wordcount linkchecker',
          toolbar:
            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table code | ' +
            'spellcheckdialog a11ycheck typography | align lineheight | numlist bullist ' +
            'indent outdent | emoticons charmap | removeformat | searchreplace',
          // tinycomments_mode: 'embedded',
          // tinycomments_author: 'Author name',
          placeholder: 'Add a note here...',
        }}
        onEditorChange={(content, editor) => {
          setNotes(content)
        }}
      />
      <div className="d-flex justify-content-end">
        {noteObj && updateNote && toggleEdit && (
          <RwButton className="m-3" onClick={() => toggleEdit(false)}>
            Cancel
          </RwButton>
        )}
        <RwButton
          className="mt-3 mb-3"
          type="submit"
          variant="primary"
          onClick={() =>
            noteObj && updateNote ? handleUpdateNote(noteObj) : handleSubmit()
          }
          disabled={notes.length === 0}
        >
          {noteObj && updateNote ? 'Save' : 'Add Note'}
        </RwButton>
      </div>
    </>
  )
}

export default NotesField
