import { PUBLIC_API_URL } from '../context/apiconfig'
import { INavMenuItem } from '../context/site/types'
import { IUserProfile } from '../queries/user/types'
import { HIDE_NAV_PATHS } from '../routes'
import { isProd } from './EnvDetect'
import { difference, differenceWith, isBoolean, isEqual } from 'lodash'

export const DEFAULT_DEBOUNCE_DELAY = 300
export const LONG_DEBOUNCE_DELAY = 600

export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID
export const PROLINE_PROJECT_ID = '14'
export const WHOLESALE_PROJECT_ID = '11'

export const onDocClick = (
  fileType: string,
  documentName: string,
  orders_id: string | undefined,
) => {
  window.location.href = `${PUBLIC_API_URL}documents/${fileType}/${documentName}/${orders_id}`
}

export const onPricelistDocClick = (listName: string, sortWord: string) => {
  const urlSuffix = `${listName}-${sortWord}`

  if (isProd()) {
    window.location.href = `${PUBLIC_API_URL}documents/s3/pricelist/${urlSuffix}`
  } else {
    window.location.href = `${PUBLIC_API_URL}documents/s3/pricelist/${urlSuffix}/isNotProd`
  }
}

export const onExcelDocClick = (
  getSpecifications: string,
  listName: string,
  levelId: number,
) => {
  window.location.href = `${PUBLIC_API_URL}documents/${getSpecifications}/${listName}/${levelId}`
}

//Validators
export const validatePhoneNumber = (value: string | undefined) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/gi, '')
  if (phoneNumber === '') {
    return true
  } else if (/^(\+?1)|(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
    return true
  } else {
    return false
  }
}

//AutoMasking Mobile & Phone Numbers
export const automaskMobilePhoneNumber = (value: string) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/g, '')
  if (phoneNumber.length === 10) {
    phoneNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '+1($1) $2-$3')
  } else if (phoneNumber.length === 13) {
    phoneNumber = phoneNumber.replace(
      /^(\+?1)(\d{3})[ ](\d{3})(\d{4})$/,
      '$1($2) $3-$4',
    )
  } else {
    if (/^(\+?1)(\d{3})[ ](\d{3})(\d{4})./.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 13)
    } else if (/^(\+?1)(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 11)
    }
  }
  return phoneNumber
}

export const automaskPhoneNumber = (value: string) => {
  let phoneNumber = typeof value === 'undefined' ? '' : value
  phoneNumber = phoneNumber.replace(/[()-]/g, '')
  if (phoneNumber.length === 10) {
    phoneNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
  } else if (phoneNumber.length === 11) {
    phoneNumber = phoneNumber.replace(
      /^(\d{3})[ ](\d{3})(\d{4})$/,
      '($1) $2-$3',
    )
  } else {
    if (/^(\d{3})[ ](\d{3})(\d{4})./.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 11)
    } else if (/^(\d{3})[ ](\d{3})(\d{4})$/.test(phoneNumber)) {
      phoneNumber = phoneNumber.substring(0, 10)
    }
  }
  return phoneNumber
}

export const automaskEmail = (value: string) => {
  return new Promise<string>((resolve) => {
    let email = typeof value === 'undefined' ? '' : value
    email = email.replace(/\s/g, '')
    resolve(email)
  })
}

export const priceApiToCss = (object: any) => {
  let bootstrapSting = ''
  if (object.strikethrough > 0) {
    bootstrapSting += ' text-decoration-line-through'
  }
  if (object.emphasize > 0) {
    bootstrapSting += ' fw-bold text-accent'
  }
  if (object.muted > 0) {
    bootstrapSting += ' text-muted'
  }
  if (object.small) {
    bootstrapSting += ' small'
  }

  return bootstrapSting
}

export const stringToNumberArray = (string: string | null) => {
  let array = string && string.split(',').map((el: any) => +`${el}`)
  return array
}

export const determineChanges = (
  oldValues: any,
  newValues: any,
  hiddenInputs: Array<string> = [],
  forceDirty: boolean = false,
) => {
  let tempChangedValues: any = {}
  hiddenInputs.forEach((input_name) => {
    tempChangedValues[input_name] = oldValues[input_name]
  })

  for (const property in newValues) {
    const oldPropValue = oldValues[property] ? oldValues[property] : ''
    const newPropValue = newValues[property] ? newValues[property] : ''

    if (Array.isArray(newPropValue)) {
      const arrayDiff = findChangedArrayEntries(
        oldPropValue,
        newPropValue,
        'id',
        forceDirty,
      )
      if (arrayDiff && arrayDiff.length > 0) {
        tempChangedValues = {
          ...tempChangedValues,
          ...{ [property]: arrayDiff },
        }
      }
    } else if (
      forceDirty ||
      (newPropValue !== oldPropValue && newPropValue !== undefined)
    ) {
      tempChangedValues = {
        ...tempChangedValues,
        ...{ [property]: newPropValue },
      }
    }
  }

  return tempChangedValues
}

function findChangedArrayEntries(
  oldArray: any,
  newArray: any,
  idProp = 'id',
  forceDirty = false,
) {
  const oldMap: any = {}
  const newMap: any = {}
  const changedEntries: any = []

  // Create maps of objects indexed by id
  oldArray.forEach((entry: any) => {
    oldMap[entry[idProp]] = entry
  })

  newArray.forEach((entry: any) => {
    newMap[entry[idProp]] = entry
  })

  // Find changes
  Object.keys(newMap).forEach((id) => {
    const oldEntry = oldMap[id]
    const newEntry = newMap[id]

    if (
      forceDirty ||
      (oldEntry && JSON.stringify(oldEntry) !== JSON.stringify(newEntry))
    ) {
      changedEntries.push({ old: oldEntry, new: newEntry })
    } else if (!oldEntry) {
      // New entry
      changedEntries.push({ old: null, new: newEntry })
    }
  })

  // Check for removed entries
  Object.keys(oldMap).forEach((id) => {
    if (!newMap[id]) {
      changedEntries.push({ old: oldMap[id], new: null })
    }
  })

  return changedEntries
}

export const stringToBoolean = (stringValue: string | undefined | boolean) => {
  if (isBoolean(stringValue)) {
    return stringValue
  }

  stringValue = stringValue + ''

  switch (stringValue?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case 'y':
    case '1':
      return true

    case 'false':
    case 'no':
    case 'n':
    case '0':
    case null:
    case undefined:
      return false

    default:
      return false
  }
}

export const stringEtcToBoolean = (
  stringValue: string | undefined | null | boolean,
) => {
  if (isBoolean(stringValue)) {
    return stringValue
  }

  if (typeof stringValue === 'string') {
    const lowerCaseValue = stringValue.toLowerCase().trim()
    switch (lowerCaseValue) {
      case 'true':
      case 'yes':
      case 'y':
      case '1':
        return true

      case 'false':
      case 'no':
      case 'n':
      case '0':
      case null:
      case undefined:
        return false

      default:
        return false
    }
  } else if (typeof stringValue === 'number') {
    if (stringValue === 1) {
      return true
    } else if (stringValue === 0) {
      return false
    }
  }
  return stringValue ?? false
}

interface KeyboardEvent {
  key: string
}
export const blurInputOnKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }
}

export const truncateText = (
  text: string,
  length: number,
  replace: string = '',
) => {
  if (text.length > length) {
    return text.substring(0, length) + replace
  }
  return text
}

export const parseJwt = (token: string) => {
  if (!token) {
    return null
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonStr = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  return JSON.parse(jsonStr)
}

export const maskCreditCardNumber = (ccNumber: string | null) => {
  if (!ccNumber) {
    return null
  }
  const lastFour = ccNumber.slice(ccNumber.length - 4)
  return `XXXX-XXXX-XXXX-${lastFour}`
}

export const isHideNavPath = (path: string) => {
  return HIDE_NAV_PATHS.includes(path)
}

export const isEmbedded = () => {
  return window.self !== window.top
}

export const getMonthName = (monthNumber: number): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  // Subtract 1 because array is zero-indexed (January is 0, December is 11)
  return monthNames[monthNumber - 1]
}

export const hasActiveItem = (
  menuItem: INavMenuItem,
  pathname: string,
  search: string,
): boolean => {
  let hasActive = false
  menuItem.children?.forEach((item: INavMenuItem) => {
    if (isActiveItem(item, pathname, search)) {
      hasActive = true
    }
  })
  return hasActive
}

export const isActiveItem = (
  menuItem: INavMenuItem,
  pathname: string,
  search: string,
): boolean => {
  if (menuItem.link_to === `${pathname}${search}`) {
    return true
  } else {
    let pathnameArray = pathname.split('/')
    if (pathnameArray.length > 1) {
      pathnameArray.pop()
      return isActiveItem(menuItem, pathnameArray.join('/'), search)
    }
  }

  return false
}

export const truncateFilePath = (pathname: string): string | null => {
  if (!pathname) {
    return null
  }
  let pathArray = pathname.split('/')
  pathArray.pop()

  return pathArray.join('/')
}
