import React from 'react'
import { Nav } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IRwNav {
  className?: string
  [x: string]: any
}

const RwNav: React.FC<IRwNav> = ({ children, className = '', ...rest }) => {
  const { isDarkMode } = useUserContext()

  return (
    <Nav className={`py-1 me-3 ${className}`} {...rest}>
      {children}
    </Nav>
  )
}

export default RwNav
