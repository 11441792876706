import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Form } from 'react-bootstrap'
import RwFormikHiddenInput from '../../../molecules/RwFormikHiddenInput'
import RwFormikInput from '../../../molecules/RwFormikInput'
import RwDatePicker from '../../../molecules/RwDatePicker'
import RwSubmitButton from '../../../molecules/RwButton/RwSubmitButton'
import { useProductContext } from '../../../../context/products/ProductProvider'
import RwFormikCheckbox from '../../../molecules/RwFormikCheckbox'

export interface IProductListDetailInitialValues {
  id: string
  label: string
  seo_meta: string
  is_active: boolean
  url: string
  start_date: string
  end_date: string
  list_type_id: string
  products_deal_id: string
  products_list_year: string
}

interface IProductListDetailFormProps {
  initialValues: IProductListDetailInitialValues
  is_create?: boolean
  handleClose?: () => void
}

const ProductListDetailForm: React.FC<IProductListDetailFormProps> = ({
  initialValues,
  is_create = false,
  handleClose = () => {},
}) => {
  const { saveProductList } = useProductContext()
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    label: Yup.string().required('Name is required'),
    seo_meta: Yup.string(),
    is_active: Yup.boolean(),
    url: Yup.string().required('URL is required'),
    start_date: Yup.string(),
    end_date: Yup.string(),
    list_type_id: Yup.string().required('Type is required'),
    products_deal_id: Yup.string(),
    products_list_year: Yup.string(),
  })

  const onSubmit = (values: any, submitProps: FormikHelpers<any>) => {
    saveProductList(values)
    if (is_create) {
      handleClose()
    }
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <RwFormikHiddenInput name="id" />
            <RwFormikInput name="label" label="Name" />
            <RwFormikInput as="textarea" name="seo_meta" label="SEO Meta" />
            <RwFormikInput name="url" label="URL" />
            <div className="d-flex justify-content-center">
              {values.start_date && <span>Start Date</span>}
              <RwDatePicker
                name="start_date"
                label="Start Date"
                flush
                clearable
              />
              {values.end_date && <span>End Date</span>}
              <RwDatePicker name="end_date" label="End Date" flush clearable />
            </div>
            <div className="pt-3">
              <RwFormikCheckbox name="is_active" label="Active" type="switch" />
            </div>
            <div className="d-flex pt-4 justify-content-end">
              <RwSubmitButton>
                {isSubmitting ? 'Saving...' : 'Save'}
              </RwSubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ProductListDetailForm
