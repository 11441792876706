import React, { useEffect, useState } from 'react'
import { Card, Form, Modal } from 'react-bootstrap'
import RwFormikInput from '../../../molecules/RwFormikInput'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { determineChanges } from '../../../../helpers'
import RwCard from '../../../molecules/RwCard'
import { useNavigate, useParams } from 'react-router-dom'
import RwFormikCheckbox from '../../../molecules/RwFormikCheckbox'
import RwSubmitButton from '../../../molecules/RwButton/RwSubmitButton'
import RwCloseButton from '../../../molecules/RwButton/RwCloseButton'
import RwButtonGroup from '../../../molecules/RwButton/RwButtonGroup'
import RwNavBar from '../../../molecules/RwNav/RwNavBar'
import {
  useGetOneManufacturer,
  useUpdateManufacturer,
} from '../../../../queries/pricing'
import { IPriceManufacturersItem } from '../../../../queries/pricing/types'
import RwDisplayField from '../../../molecules/RwDisplayField'
import RwFormikOptionSelect from '../../../molecules/RwFormikOptionSelect'
import { usePricingContext } from '../../../../context/pricing/PricingProvider'
import RwDisplayCheckbox from '../../../molecules/RwDisplayCheckbox'
import RwNavItem from '../../../molecules/RwNav/RwNavItem'
import toast from '../../../molecules/RwToast'
import AuditLog from '../../../organisms/AuditLog'
import NotesModal from '../../../organisms/Notes/NotesModal'
import { pricingUiIds } from '../../../../constants/constants'
import NotesIcon from '../../../organisms/Notes/NotesIcon'

interface IManufacturerDetailProps {
  show: boolean
  price_manufacturers_id: string
  handleClose: () => void
}

export const ManufacturerDetailBreadcrumb: React.FC = () => {
  const { manufacturer } = usePricingContext()

  return (
    <span>
      {manufacturer?.manufacturers_name
        ? `${manufacturer?.manufacturers_name}`
        : 'Loading detail...'}
    </span>
  )
}

const Detail: React.FC<IManufacturerDetailProps> = ({
  show,
  price_manufacturers_id,
  handleClose,
}) => {
  const [item, setItem] = useState<IPriceManufacturersItem>()
  const [versionName, setVersionName] = useState<string>('')
  const [versionId, setVersionId] = useState<string | null>(null)

  const {
    pricingNotes,
    getPricingNotes,
    savePricingNotes,
    updatePricingNote,
    deletePricingNote,
    cleanupPricingNotes,
    inboundOceanFreightOptions,
    getInboundOceanFreightOptions,
    setManufacturer,
  } = usePricingContext()
  const [showNotesModal, setShowNotesModal] = useState(false)
  const uiId = pricingUiIds.price_manufacturers

  const updateManufacturer = useUpdateManufacturer()

  useEffect(() => {
    getInboundOceanFreightOptions()

    return () => {
      cleanupPricingNotes()
    }
  }, [])

  const { data, refetch } = useGetOneManufacturer(price_manufacturers_id)

  useEffect(() => {
    if (data) {
      setVersionId(data.item.version_id)
      setVersionName(data.version_name)
      setItem(data.item)
      setManufacturer(data.item)
    }
  }, [data])

  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape(
    {
      multiplier: Yup.number(),
    },
    [],
  )

  const initialValues = {
    price_inbound_freight_id: item?.price_inbound_freight_id || '',
    manufacturers_name: item?.manufacturers_name || '',
    multiplier: item?.multiplier || '',
    calculated_template_value: item?.calculated_template_value || '',
    active: item?.active || '',
    manufacturers_status: item?.manufacturers_status || '',
    manufacturers_id: item?.manufacturers_id || '',
  }

  const onSubmit = async (values: any, submitProps: FormikHelpers<any>) => {
    const updatedPricingInfo = determineChanges(initialValues, values, [
      'price_manufacturers_id',
    ])

    updatedPricingInfo.id = item?.id

    setLoading(true)
    try {
      await updateManufacturer.mutateAsync(updatedPricingInfo)
      toast.success('Manufacturer updated successfully')
      refetch()
    } catch (error) {
      toast.warning('Error updating manufacturer', {
        autoClose: 5000,
      })
    } finally {
      setLoading(false)
    }
  }

  const manufacturersFieldMap = {
    price_inbound_freight_id: 'Inbound Ocean Freight Type',
    multiplier: 'Multiplier',

    calculated_template_value: 'Calculated Template Value',
    active: 'Active Pricing',
    manufacturers_status: 'Active Manufacturer',
  }

  const handleSaveNotes = (notes: string) => {
    if (versionId && uiId && price_manufacturers_id) {
      savePricingNotes(uiId, versionId, price_manufacturers_id, notes)
    }
  }

  const handleGetPricingNotes = () => {
    if (versionId && uiId && price_manufacturers_id) {
      getPricingNotes(uiId, versionId, price_manufacturers_id)
    }
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {price_manufacturers_id === 'new' ? 'Create' : 'Edit'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              values,
              isValid,
              dirty,
              resetForm,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <RwNavBar title={versionName}>
                  <RwNavItem
                    fill
                    variant="revert"
                    disabled={!dirty}
                    onClick={resetForm}
                  />
                  <RwNavItem
                    variant="save"
                    disabled={!dirty || !isValid}
                    onClick={handleSubmit}
                  />
                  <RwNavItem
                    title="Notes"
                    variant="edit"
                    onClick={() => setShowNotesModal(true)}
                  />
                  <NotesIcon count={item?.item_notes_count || 0} size="sm" />
                </RwNavBar>
                <div className="p-4">
                  <RwCard className="mb-3">
                    <Card.Header>
                      <div className="d-flex">
                        <b>{values.manufacturers_name}</b>
                        <AuditLog
                          table="price_manufacturers"
                          keyValue={price_manufacturers_id.toString()}
                          fieldMap={manufacturersFieldMap}
                        />
                      </div>
                    </Card.Header>
                    <Card.Body className="p-2">
                      <RwDisplayField
                        value={values?.manufacturers_name}
                        label="Manufacturer"
                      />
                      <RwFormikOptionSelect
                        placeholder="None"
                        name="price_inbound_freight_id"
                        label="Inbound Ocean Freight Type"
                        options={inboundOceanFreightOptions}
                      />
                      <RwDisplayField
                        value={values?.calculated_template_value.toString()}
                        label="Calculated Template Value"
                      />
                      <RwFormikInput
                        name="multiplier"
                        type="number"
                        label="Multiplier"
                      />
                      <RwFormikCheckbox name="active" label="Active Pricing" />
                      <RwDisplayCheckbox
                        label="Active Manufacturer"
                        value={item?.manufacturers_status}
                      />
                    </Card.Body>
                  </RwCard>

                  <RwButtonGroup>
                    <RwSubmitButton disabled={!dirty || !isValid}>
                      Save
                    </RwSubmitButton>
                    <RwCloseButton onClick={handleClose} />
                  </RwButtonGroup>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <NotesModal
        show={showNotesModal}
        title={`${versionName} Notes`}
        handleClose={() => {
          setShowNotesModal(false)
          refetch()
        }}
        saveNotes={handleSaveNotes}
        existingNotes={pricingNotes}
        refreshNotes={handleGetPricingNotes}
        updateNote={updatePricingNote}
        deleteNote={deletePricingNote}
      />
    </div>
  )
}

export default Detail
