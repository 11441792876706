import React from 'react'
import { BsFillCircleFill } from 'react-icons/bs'

interface INotesIcon {
  count: number
  size: 'sm' | 'lg'
}

const NotesIcon: React.FC<INotesIcon> = ({ count, size }) => {
  return (
    <div className={`notes-icon-container ${size}`}>
      {count > 0 && (
        <>
          <BsFillCircleFill color="#282878" size="1.5em" />
          <span className={`notes-count ${size}`}>{count}</span>
        </>
      )}
    </div>
  )
}

export default NotesIcon
