import React, { useState, useEffect } from 'react'
import { Row, Card, Col, Form } from 'react-bootstrap'
import RwCard from '../../../molecules/RwCard'
import RwTable from '../../../molecules/RwTable'
import RwTableCell from '../../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../../molecules/RwTable/RwTableRow'
import { Formik } from 'formik'
import RwFormikInput from '../../../molecules/RwFormikInput'
import RwFormikCheckbox from '../../../molecules/RwFormikCheckbox'
import RwButtonGroup from '../../../molecules/RwButton/RwButtonGroup'
import RwSubmitButton from '../../../molecules/RwButton/RwSubmitButton'
import * as Yup from 'yup'
import toast from '../../../molecules/RwToast'
import { useSiteContext } from '../../../../context/site/SiteProvider'
import { usePricingContext } from '../../../../context/pricing/PricingProvider'
import RwCloseButton from '../../../molecules/RwButton/RwCloseButton'
import RwFormikHiddenInput from '../../../molecules/RwFormikHiddenInput'
import RwTableCellCenter from '../../../molecules/RwTable/RwTableCellCenter'
import { priceApiToCss } from '../../../../helpers'
import RwNavItem from '../../../molecules/RwNav/RwNavItem'
import {
  IProductForPricing,
  IPricingHistoryItem,
  IProductPrice,
} from '../../../../context/pricing/types'
import { useParams, useNavigate } from 'react-router-dom'
import RwNavBar from '../../../molecules/RwNav/RwNavBar'

export const ProductsPricingBreadcrumb = () => {
  const { productForPricing } = usePricingContext()

  return (
    <span>
      {productForPricing?.products_name
        ? productForPricing.products_name
        : 'Loading...'}
    </span>
  )
}

const ProductsPricing: React.FC = () => {
  const [product, setProduct] = useState<IProductForPricing | null>(null)
  const [pricingHistory, setPricingHistory] = useState<IPricingHistoryItem[]>(
    [],
  )
  const [productPrices, setProductPrices] = useState<IProductPrice[]>([])
  const { exportToCsv } = useSiteContext()
  const {
    productForPricing,
    getProductForPricing,
    clearProductForPricing,
    updateProductBasePriceAndCosts,
  } = usePricingContext()
  const { products_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      clearProductForPricing()
    }
  }, [])

  useEffect(() => {
    if (products_id) {
      getProductForPricing(products_id)
    }
  }, [products_id])

  useEffect(() => {
    if (productForPricing) {
      setProduct(productForPricing)
      setPricingHistory(productForPricing.pricing_history)
      setProductPrices(productForPricing.level_prices)
    }
  }, [productForPricing])

  const onSubmit = async (values: any) => {
    if (product) {
      await updateProductBasePriceAndCosts(values)
      if (productForPricing) {
        setProduct(productForPricing)
        setPricingHistory(productForPricing.pricing_history)
        setProductPrices(productForPricing.level_prices)
      }
    } else {
      toast.info('No data to save. Please select a product first.')
    }
  }

  const handleClose = () => {
    navigate('/accounting/products-pricing')
  }

  const initialValues = {
    products_id: product?.products_id || '',
    products_name: product?.products_name || '',
    products_description: product?.products_description || '',
    products_base_price: product?.base_price || '',
    products_fob_cost: product?.fob_cost || '',
    products_landed_cost: product?.landed_cost || '',
    additional_cost_1: product?.additional_cost_1 || '',
    additional_cost_2: product?.additional_cost_2 || '',
    additional_cost_3: product?.additional_cost_3 || '',
    products_price_closeout: product?.closeout_price || '',
    is_non_discountable: product?.product_is_no_discount || false,
    is_on_closeout: product?.is_on_closeout || false,
    is_fixed_price: product?.is_fixed_price || false,
    is_tax_exempt: product?.is_tax_exempt || false,
  }

  const validationSchema = Yup.object({})

  const handleIntegerCurrencyFields = (e: any, setFieldValue: Function) => {
    const val = e.target.value
    if (!val.includes('.') && Number.isInteger(+val)) {
      setFieldValue(e.target.name, `${val}.00`)
    }
  }

  const handleExportHistory = () => {
    if (!product || pricingHistory.length < 1) {
      toast.info('No data to export.')
      return
    }
    const headers = [
      'Date Entered',
      'Price Deployment Name',
      'User',
      'Action',
      'Type',
      'Value',
      'Message',
    ]
    const filename = 'pricing_history_' + product.products_model + '.csv'
    const filteredHistory = pricingHistory.map(
      ({ price_log_id, ...item }) => item,
    )
    exportToCsv({ headers, data: filteredHistory }, filename)
  }

  return (
    <>
      <Row>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue, dirty, resetForm }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <RwNavBar
                title={`Pricing - ${product?.products_name || 'Loading...'}`}
              >
                <RwNavItem variant="close" onClick={handleClose} fill />
                <RwNavItem
                  variant="revert"
                  onClick={resetForm}
                  disabled={!dirty}
                />
              </RwNavBar>
              <RwCard className="m-3">
                <Card.Header className="fw-bold">
                  {product
                    ? product?.products_model + ' - ' + product?.products_name
                    : 'Select a product to view and edit'}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xl={4}>
                      <RwFormikHiddenInput name="products_id" />
                      <RwFormikInput name="products_name" disabled />
                      <RwFormikInput
                        as="textarea"
                        rows={6}
                        name="products_description"
                        disabled
                      />
                    </Col>
                    <Col xl={2}>
                      <RwFormikInput
                        name="products_base_price"
                        label="Base Price"
                        onBlur={(e: any) => {
                          handleIntegerCurrencyFields(e, setFieldValue)
                        }}
                      />
                      <RwFormikInput
                        name="products_fob_cost"
                        label="FOB Cost"
                        onBlur={(e: any) => {
                          handleIntegerCurrencyFields(e, setFieldValue)
                        }}
                      />
                      <RwFormikInput
                        name="products_landed_cost"
                        label="Landed Cost"
                        onBlur={(e: any) => {
                          handleIntegerCurrencyFields(e, setFieldValue)
                        }}
                      />
                    </Col>
                    <Col xl={2}>
                      {/* These are placeholders, finance says we'll have more costs to track */}
                      <RwFormikInput
                        name="additional_cost_1"
                        label="Additional Cost"
                        disabled
                      />
                      <RwFormikInput
                        name="additional_cost_2"
                        label="Additional Cost"
                        disabled
                      />
                      <RwFormikInput
                        name="additional_cost_3"
                        label="Additional Cost"
                        disabled
                      />
                    </Col>
                    <Col xl={2}>
                      <RwFormikInput
                        name="products_price_closeout"
                        label="Closeout Price"
                        onBlur={(e: any) => {
                          handleIntegerCurrencyFields(e, setFieldValue)
                        }}
                      />
                    </Col>
                    <Col xl={2}>
                      <RwFormikCheckbox
                        type="switch"
                        name="is_non_discountable"
                        label="Non-Discountable Product"
                      />
                      <RwFormikCheckbox
                        type="switch"
                        name="is_on_closeout"
                        label="On Closeout"
                      />
                      <RwFormikCheckbox
                        type="switch"
                        name="is_fixed_price"
                        label="Fixed Price"
                      />
                      <RwFormikCheckbox
                        type="switch"
                        name="is_tax_exempt"
                        label="Tax Exempt"
                        disabled
                      />
                      <RwButtonGroup>
                        <RwSubmitButton>Save</RwSubmitButton>
                        <RwCloseButton onClick={handleClose} />
                      </RwButtonGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </RwCard>
            </Form>
          )}
        </Formik>
      </Row>
      <Row className="d-flex">
        <Col lg={6}>
          <RwCard className="m-3">
            <Card.Header className="d-flex justify-content-between fw-bold align-items-center">
              Pricing History
              <RwNavItem variant="csv" onClick={handleExportHistory} />
            </Card.Header>
            <Card.Body>
              <RwTable hover data={product?.pricing_history}>
                <RwTableHead>
                  <RwTableRow>
                    <RwTableCell as="th">Date</RwTableCell>
                    <RwTableCell as="th">Description</RwTableCell>
                  </RwTableRow>
                </RwTableHead>
                <RwTableBody>
                  {pricingHistory &&
                    pricingHistory.map(
                      (item: IPricingHistoryItem, index: number) => {
                        return (
                          <RwTableRow key={index}>
                            <RwTableCell>{item.date_entered}</RwTableCell>
                            <RwTableCell>{item.message}</RwTableCell>
                          </RwTableRow>
                        )
                      },
                    )}
                </RwTableBody>
              </RwTable>
            </Card.Body>
          </RwCard>
        </Col>
        <Col lg={6}>
          <RwCard className="m-3">
            <Card.Header className="fw-bold">Current Prices</Card.Header>
            <Card.Body>
              <RwTable hover>
                <RwTableHead>
                  <RwTableRow>
                    <RwTableCell as="th">Price Level</RwTableCell>
                    <RwTableCellCenter as="th">Level Price</RwTableCellCenter>
                    {product?.has_discount_lines ? (
                      <RwTableCellCenter as="th">
                        Sale/Closeout Price
                      </RwTableCellCenter>
                    ) : (
                      <></>
                    )}
                  </RwTableRow>
                </RwTableHead>
                <RwTableBody>
                  {productPrices &&
                    productPrices.map((price: IProductPrice, index: number) => {
                      const firstLine = price.display_lines[0]
                      const secondLine = price.display_lines[1] || null
                      return (
                        <RwTableRow key={price.product_prices_id}>
                          <RwTableCell>{price.price_levels_name}</RwTableCell>
                          <RwTableCellCenter
                            className={priceApiToCss(firstLine)}
                          >
                            {firstLine.amount}
                          </RwTableCellCenter>
                          {product?.has_discount_lines ? (
                            <RwTableCellCenter
                              className={priceApiToCss(secondLine)}
                            >
                              {price.display_lines[1].amount}
                            </RwTableCellCenter>
                          ) : (
                            <></>
                          )}
                        </RwTableRow>
                      )
                    })}
                </RwTableBody>
              </RwTable>
            </Card.Body>
          </RwCard>
        </Col>
      </Row>
    </>
  )
}

export default ProductsPricing
