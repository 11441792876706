import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import { INoteData } from '../../../context/site/types'
import StaffAvatarIcon from '../../organisms/Common/Icon/StaffAvatarIcon'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'
import RowContextMenuItem from '../../molecules/RwTable/RowContextMenuItem'
import RowContextMenu from '../../molecules/RwTable/RowContextMenu'
import { useUserContext } from '../../../context/user/UserProvider'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import NotesField from './NotesField'

interface INotesListItemProps {
  note: INoteData
  isDarkMode: boolean
  user: IUserData | undefined
  handleDeleteNote: (note: INoteData) => void
  handlePinUnpinNote: (note: INoteData) => void
  handleUpdateNote?: (note: INoteData) => void
}

const NotesList: React.FC<INotesListItemProps> = ({
  note,
  isDarkMode,
  user,
  handleDeleteNote,
  handlePinUnpinNote,
  handleUpdateNote,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const handleEditNote = () => {
    setIsEditing(!isEditing)
  }

  const pinnedClass = note.is_pinned
    ? isDarkMode
      ? 'note-pinned-dm'
      : 'note-pinned'
    : ''

  return (
    <ListGroup.Item key={note.id}>
      <div className={`${pinnedClass} d-flex p-3`}>
        <div className="d-flex me-2">
          {user && (
            <RowContextMenu isModal rootClose>
              <RowContextMenuItem
                variant="edit"
                label="Edit"
                handleClick={() => handleEditNote()}
                disabled={user.staff_id !== note.staff_id}
              />
              <RowContextMenuItem
                variant="remove"
                label="Delete"
                handleClick={() => handleDeleteNote(note)}
                disabled={user.staff_id !== note.staff_id}
              />
              <RowContextMenuItem
                variant="pin"
                label="Pin"
                handleClick={() => handlePinUnpinNote(note)}
              />
            </RowContextMenu>
          )}
          <StaffAvatarIcon
            photo_url={note.staff_photo_url}
            staff_name={note.staff_name}
          />
        </div>
        <div style={{ width: '90%' }}>
          <div className="fw-bold text-capitalized-flex d-flex">
            {note.staff_name}
            <span className="fst-italic ms-2">{note.date_added}</span>
            {note.is_pinned && (
              <RwNavItem
                variant="pin"
                title="Pinned"
                onClick={() => handlePinUnpinNote(note)}
                fill
              />
            )}
          </div>
          {isEditing ? (
            <NotesField
              noteObj={note}
              updateNote={handleUpdateNote}
              toggleEdit={setIsEditing}
            />
          ) : (
            <div className="mt-3 border rounded p-3 shadow d-flex">
              <div
                className="m-2"
                dangerouslySetInnerHTML={{ __html: note.note }}
              ></div>
            </div>
          )}
          {note.last_updated && (
            <div className="d-flex">
              <span className="fst-italic fs-6 mt-2 ms-auto">
                Last edited on {note.last_updated}
              </span>
            </div>
          )}
        </div>
      </div>
    </ListGroup.Item>
  )
}

export default NotesList
