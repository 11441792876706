import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { determineChanges } from '../../../../../../helpers'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import RwSubmitButton from '../../../../../molecules/RwButton/RwSubmitButton'
import RwCloseButton from '../../../../../molecules/RwButton/RwCloseButton'
import { useProductContext } from '../../../../../../context/products/ProductProvider'
import RwButtonGroup from '../../../../../molecules/RwButton/RwButtonGroup'
import RwNavBar from '../../../../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../../../../molecules/RwNav/RwNavItem'
import {
  IProductColor,
  IProductDebrisProfile,
  IProductEffect,
  IProductFiringPattern,
} from '../../../../../../context/products/types'
import {
  COLOR_PREFIX,
  EFFECT_PREFIX,
  FIRING_PATTERN_PREFIX,
  DEBRIS_PROFILE_PREFIX,
} from '../../../../../../constants/constants'
import ProtectedForm from '../../../../../molecules/ProtectedForm'
import { PRODUCT_EDITOR_PRO_LINE_UPDATE_PERM } from '../../../../../../constants/iam'
import ProLineForm from './pro-line-form'
import ErrorComponent from '../../errors'
import { TAB_BACK_PATH } from '../common/common'
import { isAuthorized } from '../../../../../../helpers/Authorization'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../../../../queries/user/keyFactory'
import { IUserData } from '../../../../../../queries/user/types'
import RequestUpdatesModal from '../common/request-updates-modal'

export const ProductBreadcrumb: React.FC = () => {
  const { product } = useProductContext()

  return (
    <span>
      {product?.products_model
        ? `${product.products_model} - ${
            product.products_name || 'Unnamed Product'
          }`
        : 'New Product'}
    </span>
  )
}

const ProLineTab: React.FC = () => {
  const { products_id } = useParams()

  const [showPrintModal, setShowPrintModal] = useState(false)
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleRequest = () => {
    setShowModal(true)
  }

  const navigate = useNavigate()
  const location = useLocation()

  const {
    product,
    productColors,
    productEffects,
    productFiringPatterns,
    productDebrisProfiles,
    getProductColors,
    getProductEffects,
    getProductFiringPatterns,
    getProductDebrisProfiles,
    getProduct,
    clearProductContext,
    updateProduct,
  } = useProductContext()

  useEffect(() => {
    getProductColors()
    getProductEffects()
    getProductFiringPatterns()
    getProductDebrisProfiles()

    return () => {
      clearProductContext()
    }
  }, [])

  useEffect(() => {
    if (products_id && products_id != 'new') {
      getProduct(products_id)
    }
  }, [products_id])

  if (
    !product ||
    !productColors ||
    !productEffects ||
    !productFiringPatterns ||
    !productDebrisProfiles
  ) {
    return <></>
  }

  const validationSchema = Yup.object().shape(
    {
      products_model: Yup.string().required('Required'),
      products_name: Yup.string().required('Required'),
      products_description: Yup.string().required('Required'),
      proline_description: Yup.string(),
      products_shots: Yup.number().typeError(
        'Product Shots should be a number',
      ),
      products_duration: Yup.number().typeError(
        'Product Duration should be a number',
      ),
      performance_height: Yup.number().typeError(
        'Performance Height should be a number',
      ),
      performance_prefire_time: Yup.number(),
      calibers: Yup.array().of(
        Yup.object().shape({
          caliber_value: Yup.number()
            .typeError('Caliber should be a number')
            .required('Caliber is Required'),
          caliber_units: Yup.string().required('Caliber Units are Required'),
        }),
      ),
      net_explosive_quantity: Yup.number(),
      is_outdoor_use_only: Yup.boolean(),
      safety_distance: Yup.number().typeError(
        'Safety Distance should be a number',
      ),
      has_performance_attributes: Yup.boolean(),
      is_on_wholesale_site: Yup.boolean(),
      is_on_proline_site: Yup.boolean(),
      products_ignition: Yup.string(),
      products_ex_number: Yup.string(),
      products_un_number: Yup.string(),
      pro_use_only: Yup.boolean(),
      f3d_type: Yup.string(),
      f3d_subtype: Yup.string(),
      cobra_type: Yup.string(),
      products_vdl: Yup.string(),
    },
    [],
  )

  const initialValuesStatic = {
    products_id: product?.products_id || '',
    products_model: product?.products_model || '',
    products_name: product?.products_name || '',
    products_description: product?.products_description || '',
    proline_description: product?.proline_description || '',
    products_duration: product?.products_duration || '',
    performance_height: product?.performance_height || '',
    performance_prefire_time: product?.performance_prefire_time || '',
    net_explosive_quantity: product?.net_explosive_quantity || '',
    is_outdoor_use_only: product?.is_outdoor_use_only || false,
    safety_distance: product?.safety_distance || '',
    has_performance_attributes: product?.has_performance_attributes || false,
    is_on_wholesale_site: product?.is_on_wholesale_site || false,
    is_on_proline_site: product?.is_on_proline_site || false,
    products_shots: product?.products_shots || '',
    products_ignition: product?.products_ignition || '',
    products_ex_number: product?.products_ex_number || '',
    products_un_number: product?.products_un_number || '',
    pro_use_only: product?.pro_use_only || '',
    calibers: product?.calibers || [],
    f3d_type: product?.f3d_type || '',
    f3d_subtype: product?.f3d_subtype || '',
    cobra_type: product?.cobra_type || '',
    products_vdl: product?.products_vdl || '',
  }

  let initialValuesColors: any
  productColors.forEach((color: IProductColor) => {
    const selected = product?.colors.some((selectedColor: IProductColor) => {
      return color.id === selectedColor.id
    })
    const initialValue = {
      [`${COLOR_PREFIX}${color.id}`]: selected,
    }
    initialValuesColors = { ...initialValuesColors, ...initialValue }
  })

  let initialValuesEffects: any
  productEffects.forEach((effect: IProductEffect) => {
    const selected = product?.effects.some((selectedEffect: IProductEffect) => {
      return effect.id === selectedEffect.id
    })
    const initialValue = {
      [`${EFFECT_PREFIX}${effect.id}`]: selected,
    }
    initialValuesEffects = { ...initialValuesEffects, ...initialValue }
  })

  let initialValuesFiringPatterns: any
  productFiringPatterns.forEach((firing_pattern: IProductFiringPattern) => {
    const selected = product?.firing_patterns.some(
      (selectedFiringPattern: IProductFiringPattern) => {
        return firing_pattern.id === selectedFiringPattern.id
      },
    )
    const initialValue = {
      [`${FIRING_PATTERN_PREFIX}${firing_pattern.id}`]: selected,
    }
    initialValuesFiringPatterns = {
      ...initialValuesFiringPatterns,
      ...initialValue,
    }
  })

  let initialValuesDebrisProfiles: any
  productDebrisProfiles.forEach((debris_profile: IProductDebrisProfile) => {
    const selected = product?.debris_profiles.some(
      (selectedDebrisProfile: IProductDebrisProfile) => {
        return debris_profile.id === selectedDebrisProfile.id
      },
    )
    const initialValue = {
      [`${DEBRIS_PROFILE_PREFIX}${debris_profile.id}`]: selected,
    }
    initialValuesDebrisProfiles = {
      ...initialValuesDebrisProfiles,
      ...initialValue,
    }
  })

  const initialValues = {
    ...initialValuesStatic,
    ...initialValuesColors,
    ...initialValuesEffects,
    ...initialValuesFiringPatterns,
    ...initialValuesDebrisProfiles,
  }

  const onSubmit = (values: any, submitProps: FormikHelpers<any>) => {
    const updatedProductInfo = product
      ? determineChanges(initialValues, values, ['products_id'])
      : { ...values, products_id: null }

    updateProduct(updatedProductInfo)
  }

  const handleClose = () => {
    navigate(TAB_BACK_PATH)
  }

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          isValid,
          dirty,
          setFieldValue,
          resetForm,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ProtectedForm permission={PRODUCT_EDITOR_PRO_LINE_UPDATE_PERM}>
              {!isValid && <ErrorComponent errors={errors} />}
              <RwNavBar>
                <RwNavItem variant="close" onClick={handleClose} />
                <RwNavItem
                  fill
                  variant="revert"
                  disabled={!dirty}
                  onClick={resetForm}
                />
                <RwNavItem
                  variant="save"
                  disabled={!dirty || !isValid}
                  onClick={() => {
                    if (dirty && isValid) {
                      handleSubmit()
                    }
                  }}
                />
                {!isAuthorized(user, [PRODUCT_EDITOR_PRO_LINE_UPDATE_PERM]) && (
                  <RwNavItem
                    variant="request-updates"
                    onClick={handleRequest}
                  />
                )}
              </RwNavBar>
              <div>
                <ProLineForm
                  values={values}
                  productColors={productColors}
                  productFiringPatterns={productFiringPatterns}
                  productEffects={productEffects}
                  productDebrisProfiles={productDebrisProfiles}
                />
                <RwButtonGroup className="m-4">
                  <RwSubmitButton disabled={!dirty || !isValid}>
                    Save
                  </RwSubmitButton>
                  <RwCloseButton onClick={handleClose} />
                </RwButtonGroup>
              </div>
            </ProtectedForm>
          </Form>
        )}
      </Formik>
      <RequestUpdatesModal show={showModal} handleClose={handleCloseModal} />
    </div>
  )
}

export default ProLineTab
