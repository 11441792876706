import React, { useEffect, useState } from 'react'
import {
  useProductList,
  useCagetoriesForFilter,
  useBrandsForFilter,
  useSuppliersForFilter,
} from '../../../queries/products'
import CdnImg from '../../organisms/Common/CdnImg'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import { useLocation, useNavigate } from 'react-router-dom'
import RwNav from '../../molecules/RwNav'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import { toast } from 'react-toastify'
import CustomSelect from '../../molecules/Select/CustomSelect'
import useDebounce from '../../../hooks/useDebounce'
import SearchInput from '../../molecules/SearchInput'
import { ISelectOption } from '../../../types/interfaces'
import { uniqueId } from 'lodash'
import RowContextMenu from '../../molecules/RwTable/RowContextMenu'
import RowContextMenuItem from '../../molecules/RwTable/RowContextMenuItem'
import { IProductData } from '../../../context/products/types'
import { Form } from 'react-bootstrap'
import RwTableCellCenter from '../../molecules/RwTable/RwTableCellCenter'
import DisplayCheck from '../../atoms/DisplayCheck'
import { maxProductsEditorListResults } from '../../../constants/constants'
import { PRODUCT_EDITOR_PURCHASING_UPDATE_PERM } from '../../../constants/iam'
import AddProductModal from './add-product-modal'

export const PAGE_KEY = 'settings.staff-accounts'

export const ProductsBreadcrumb: React.FC = () => {
  return <span>List</span>
}

const Products: React.FC = () => {
  const [searchText, setSearchText] = useState<any>('')
  const debouncedSearchText = useDebounce(searchText, 500)
  const [showAddProductModal, setShowAddProductModal] = useState(false)

  const handleNew = () => {
    setShowAddProductModal(true)
  }

  const handleCloseAddProductModal = () => {
    setShowAddProductModal(false)
  }

  const [isMaxResults, setIsMaxResults] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>({
    products_status: '1',
    search_text: debouncedSearchText,
    sort_by: 'products_model',
  })
  const navigate = useNavigate()
  const location = useLocation()
  const { data, refetch, isLoading, isFetching, isPreviousData, status } =
    useProductList(filter)

  const { data: categories } = useCagetoriesForFilter()
  const { data: brands } = useBrandsForFilter()
  const { data: suppliers } = useSuppliersForFilter()

  useEffect(() => {
    if (data && data?.length >= maxProductsEditorListResults) {
      setIsMaxResults(true)
    } else {
      setIsMaxResults(false)
    }
  }, [data])

  const handleDoubleClick = (products_id: string) => {
    navigate(`${location.pathname}/${products_id}/dashboard`)
  }

  const handleRefresh = () => {
    refetch()
    toast.success('Data Reloaded')
  }

  const handleStatusFilter = (status: string) => {
    setFilter({ ...filter, products_status: status !== '99' ? status : '' })
  }

  const handleCategoryFilter = (categories_id: string) => {
    setFilter({ ...filter, categories_id: categories_id })
  }

  const handleBrandFilter = (brand_id: string) => {
    setFilter({ ...filter, brand_id: brand_id })
  }

  const handleSupplierFilter = (suppliers_id: string) => {
    setFilter({ ...filter, suppliers_id: suppliers_id })
  }

  const handleWholesaleFilter = (is_on_wholesale_site: boolean) => {
    setFilter({ ...filter, is_on_wholesale_site: is_on_wholesale_site })
  }

  const handleProlineFilter = (is_on_proline_site: boolean) => {
    setFilter({ ...filter, is_on_proline_site: is_on_proline_site })
  }

  const handleGroupBy = (group_by: string) => {
    setFilter({ ...filter, group_by: group_by })
  }

  const handleSortBy = (sort_by: string) => {
    setFilter({ ...filter, sort_by: sort_by })
  }

  const handleEditItem = (products_id: string) => {
    handleDoubleClick(products_id)
  }

  const handleResetFilters = () => {
    setFilter({
      products_status: '1',
      search_text: '',
      sort_by: 'products_model',
    })

    let wholesale_checkbox = document.getElementById(
      'show_on_wholesale',
    ) as HTMLInputElement
    wholesale_checkbox.checked = false

    let proline_checkbox = document.getElementById(
      'show_on_proline',
    ) as HTMLInputElement
    proline_checkbox.checked = false

    let search_textbox = document.getElementById(
      'product_search_input',
    ) as HTMLInputElement
    search_textbox.value = ''

    setSearchText('')
  }

  // Not currently implemented
  // const handleDeleteItem = (staff_id: string) => {
  //   deleteStaffAccount(staff_id)
  // }

  useEffect(() => {
    if (debouncedSearchText.length > 2 || debouncedSearchText.length === 0) {
      setFilter({ ...filter, search_text: debouncedSearchText })
    }
  }, [debouncedSearchText])

  const statusFilterOptions: Array<ISelectOption> = [
    { value: '1', label: 'Active', id: uniqueId() },
    { value: '0', label: 'Inactive', id: uniqueId() },
    { value: '2', label: 'Archived', id: uniqueId() },
    { value: '99', label: 'All', id: uniqueId() },
  ]

  const groupByOptions: Array<ISelectOption> = [
    { value: 'brand', label: 'Brand', id: uniqueId() },
    { value: 'supplier', label: 'Supplier', id: uniqueId() },
    { value: 'category', label: 'Category', id: uniqueId() },
  ]

  const sortByOptions: Array<ISelectOption> = [
    { value: 'products_model', label: 'Model', id: 'products_model' },
    { value: 'products_name', label: 'Name', id: 'products_name' },
  ]

  return (
    <div>
      <RwNavBar>
        <RwNavItem
          permission={PRODUCT_EDITOR_PURCHASING_UPDATE_PERM}
          variant="add"
          onClick={handleNew}
        />
        <RwNavItem variant="refresh" onClick={handleRefresh} />
        <RwNav className="ms-auto">
          <CustomSelect
            title="Group By"
            width="110px"
            handleSelect={handleGroupBy}
            options={groupByOptions}
            unknownValueDisplay="Unknown Group"
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={filter.sort_by}
            title="Sort By"
            width="110px"
            handleSelect={handleSortBy}
            options={sortByOptions}
            placement="left-start"
            unknownValueDisplay="Unknown Sort"
          />
        </RwNav>
      </RwNavBar>

      <RwNavBar expand="xxl">
        <RwNav>
          <CustomSelect
            initialValue={filter.products_status}
            title="Status"
            width="110px"
            handleSelect={handleStatusFilter}
            options={statusFilterOptions}
            hideClear
            unknownValueDisplay="Unknown Status"
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={filter.brand_id}
            title="Brand"
            width="150px"
            handleSelect={handleBrandFilter}
            options={brands !== undefined ? brands : []}
            unknownValueDisplay="Unknown Brand"
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={filter.suppliers_id}
            title="Supplier"
            width="150px"
            handleSelect={handleSupplierFilter}
            options={suppliers !== undefined ? suppliers : []}
            unknownValueDisplay="Unknown Supplier"
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={filter.categories_id}
            title="Category"
            width="150px"
            handleSelect={handleCategoryFilter}
            options={categories !== undefined ? categories : []}
            unknownValueDisplay="Unknown Category"
          />
        </RwNav>
        <RwNav>
          <SearchInput
            id="product_search_input"
            width="250px"
            flush
            handleChange={(text) => {
              setSearchText(text)
            }}
          />
        </RwNav>
        <RwNav>
          <Form.Check
            className="me-1"
            id="show_on_wholesale"
            label="Wholesale"
            onChange={(e) => {
              handleWholesaleFilter(e.target.checked)
            }}
          />
        </RwNav>
        <RwNav>
          <Form.Check
            id="show_on_proline"
            label="ProLine"
            onChange={(e) => {
              handleProlineFilter(e.target.checked)
            }}
          />
        </RwNav>
        <div className="ms-auto">
          <RwNavItem variant="reset-filters" onClick={handleResetFilters} />
        </div>
      </RwNavBar>
      <div className="p-3">
        {isMaxResults ? (
          `Max results of ${maxProductsEditorListResults} reached. Please use filters to narrow your search.`
        ) : (
          <div className="mb-1">{data?.length} results</div>
        )}
        <RwTable hover>
          <RwTableHead>
            <RwTableRow>
              <RwTableCell as="th" style={{ width: '1%' }}></RwTableCell>
              <RwTableCell as="th">Model</RwTableCell>
              <RwTableCell as="th">Name</RwTableCell>
              <RwTableCell as="th" expand="xxl">
                Brand
              </RwTableCell>
              <RwTableCell as="th" expand="xxl">
                Supplier
              </RwTableCell>
              <RwTableCell as="th" expand="md">
                Category
              </RwTableCell>
              <RwTableCellCenter as="th" expand="lg">
                Wholesale
              </RwTableCellCenter>
              <RwTableCellCenter as="th" expand="lg">
                ProLine
              </RwTableCellCenter>
              <RwTableCell as="th" />
            </RwTableRow>
          </RwTableHead>
          <RwTableBody>
            {data ? (
              data?.map((item: IProductData) => {
                const rowOpacity = item.products_status === 1 ? 1 : 0.5

                return item.group_header ? (
                  <RwTableRow key={item.group_name}>
                    <RwTableCellCenter colSpan={7}>
                      <div className="p-1 fs-5 fw-bold">{item.group_name}</div>
                    </RwTableCellCenter>
                  </RwTableRow>
                ) : (
                  <RwTableRow
                    onDoubleClick={() => {
                      handleDoubleClick(item.products_id)
                    }}
                    key={item.products_id}
                    style={{ opacity: rowOpacity }}
                  >
                    <RwTableCell>
                      <CdnImg
                        width="35px"
                        className="rounded"
                        path={
                          item.image_url
                            ? `_store/images/250/${item.image_url}`
                            : '_store/images/no_photo.png'
                        }
                      />
                    </RwTableCell>
                    <RwTableCell>{item.products_model}</RwTableCell>
                    <RwTableCell>{item.products_name}</RwTableCell>
                    <RwTableCell expand="xxl">
                      {item.manufacturers_name}
                    </RwTableCell>
                    <RwTableCell expand="xxl">
                      {item.suppliers_name}
                    </RwTableCell>
                    <RwTableCell expand="md">{item.category_name}</RwTableCell>
                    <RwTableCellCenter className="border-start" expand="lg">
                      <DisplayCheck value={item.is_on_wholesale_site} />
                    </RwTableCellCenter>
                    <RwTableCellCenter className="border-start" expand="lg">
                      <DisplayCheck value={item.is_on_proline_site} />
                    </RwTableCellCenter>
                    <RwTableCell className="border-start">
                      <RowContextMenu>
                        <RowContextMenuItem
                          variant="edit"
                          handleClick={() => {
                            handleEditItem(item.products_id)
                          }}
                        />
                        {/* <RowContextMenuItem
                        variant="remove"
                        handleClick={() => {
                          handleDeleteItem(item.staff_id)
                        }}
                      /> */}
                      </RowContextMenu>
                    </RwTableCell>
                  </RwTableRow>
                )
              })
            ) : (
              <></>
            )}
          </RwTableBody>
        </RwTable>
        <AddProductModal
          show={showAddProductModal}
          handleClose={handleCloseAddProductModal}
        />
      </div>
    </div>
  )
}

export default Products
