import React, { useEffect, useState } from 'react'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import { toast } from 'react-toastify'
import { useSiteContext } from '../../../context/site/SiteProvider'
import RowContextMenu from '../../molecules/RwTable/RowContextMenu'
import RowContextMenuItem from '../../molecules/RwTable/RowContextMenuItem'
import ModalConfirmation from '../../organisms/Common/Modal/ModalConfirmation'
import { Form } from 'react-bootstrap'
import { useIamContext } from '../../../context/iam/IamProvider'
import { IRoleData, IUserProfileData } from '../../../context/iam/types'
import RoleSearchModal from '../../organisms/Common/Modal/RoleSearchModal'
import UserProfileSearchModal from '../../organisms/Common/Modal/UserProfileSearchModal'
import { IAM_UPDATE_PERM } from '../../../constants/iam'

export const UserRolesBreadcrumb: React.FC = () => {
  const { user } = useIamContext()
  return <span>{user?.name ? user?.name : ''}</span>
}

const UserRoles: React.FC = () => {
  const { staff_id } = useParams()

  const [selectedRoles, setSelectedRoles] = useState<Array<string>>([])

  const {
    user,
    getUser,
    addUserRoles,
    addUserRolesFromProfile,
    removeUserRoles,
  } = useIamContext()

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [showConfirmDeleteSelected, setShowConfirmDeleteSelected] =
    useState<boolean>(false)
  const [showRoleSearch, setShowRoleSearch] = useState<boolean>(false)
  const [showUserProfileSearch, setShowUserProfileSearch] =
    useState<boolean>(false)

  const [deleteId, setDeleteId] = useState<string>('')

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!staff_id) {
      return
    }

    getUser(staff_id)

    return () => {
      // clearUserContext()
    }
  }, [staff_id])

  if (!user?.staff_id) {
    return <></>
  }

  const addToSelected = (id: string | undefined) => {
    if (id) {
      setSelectedRoles([id, ...selectedRoles])
    }
  }

  const removeFromSelected = (id: string | undefined) => {
    if (id) {
      setSelectedRoles(
        selectedRoles.filter((role_id) => {
          return role_id !== id
        }),
      )
    }
  }

  const selectAll = () => {
    setSelectedRoles(
      user.roles?.map((role: IRoleData) => {
        return role.id
      }),
    )
    const elems = document.getElementsByName(
      'row-check',
    ) as NodeListOf<HTMLInputElement>
    if (elems) {
      elems.forEach((elem: HTMLInputElement) => {
        elem.checked = true
      })
    }
  }

  const selectNone = () => {
    setSelectedRoles([])
    const elems = document.getElementsByName(
      'row-check',
    ) as NodeListOf<HTMLInputElement>
    if (elems) {
      elems.forEach((elem: HTMLInputElement) => {
        elem.checked = false
      })
    }
  }

  const handleDeleteItemClick = (id: string | undefined) => {
    setDeleteId(id ? id : '')
    setShowConfirmDelete(true)
  }

  const handleDeleteItemConfirm = () => {
    removeUserRoles(user.staff_id, [deleteId], () => {
      getUser(user.staff_id)
      toast.success('Role Removed From User')
    })
    selectNone()
  }

  const handleDeleteSelectedClick = () => {
    setShowConfirmDeleteSelected(true)
  }

  const handleDeleteSelectedConfirm = () => {
    removeUserRoles(user.staff_id, selectedRoles, () => {
      getUser(user.staff_id)
      toast.success('Roles Removed From User')
    })
    selectNone()
  }

  const handleClose = () => {
    navigate(-1)
  }

  return (
    <div>
      <RwNavBar>
        <RwNavItem variant="close" onClick={handleClose} />
        <RwNavItem
          variant="add"
          title="Add Roles"
          permission={IAM_UPDATE_PERM}
          onClick={() => setShowRoleSearch(true)}
        />
        <RwNavItem
          variant="add"
          title="Add Roles From Profile"
          permission={IAM_UPDATE_PERM}
          onClick={() => setShowUserProfileSearch(true)}
        />
        {selectedRoles.length > 0 && (
          <>
            <RwNavItem
              variant="remove"
              permission={IAM_UPDATE_PERM}
              title="Remove Selected"
              onClick={handleDeleteSelectedClick}
            />
          </>
        )}
      </RwNavBar>

      <div className="p-3">
        <div className="mb-1">{user.roles.length} results</div>
        <RwTable hover data={user.roles}>
          <RwTableHead>
            <RwTableRow>
              <RwTableCell as="th" style={{ width: '1%' }}>
                <Form.Check
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      selectAll()
                    } else {
                      selectNone()
                    }
                  }}
                  name="row-check"
                  className="fs-5"
                />
              </RwTableCell>
              <RwTableCell as="th">Role Name</RwTableCell>
              <RwTableCell as="th">Description</RwTableCell>
              <RwTableCell as="th" style={{ width: '1%' }}></RwTableCell>
            </RwTableRow>
          </RwTableHead>
          <RwTableBody>
            {user.roles.map((item: IRoleData) => {
              return (
                <RwTableRow key={item.id}>
                  <RwTableCell className={`border-end`}>
                    <Form.Check
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                          addToSelected(item.id)
                        } else {
                          removeFromSelected(item.id)
                        }
                      }}
                      name="row-check"
                      className="fs-5"
                    />
                  </RwTableCell>
                  <RwTableCell>{item.role_name}</RwTableCell>
                  <RwTableCell>{item.description}</RwTableCell>
                  <RwTableCell className={`border-start`}>
                    <RowContextMenu>
                      <RowContextMenuItem
                        variant="remove"
                        permission={IAM_UPDATE_PERM}
                        label="Remove From User"
                        handleClick={() => {
                          handleDeleteItemClick(item.id)
                        }}
                      />
                    </RowContextMenu>
                  </RwTableCell>
                </RwTableRow>
              )
            })}
          </RwTableBody>
        </RwTable>
      </div>
      <ModalConfirmation
        showModal={showConfirmDelete}
        setShowModal={setShowConfirmDelete}
        handleConfirm={handleDeleteItemConfirm}
        title="Remove Role"
        confirmButtonText="Remove"
        body={
          <span className="fs-5">
            Are you sure you want to remove the role?
          </span>
        }
      />
      <ModalConfirmation
        showModal={showConfirmDeleteSelected}
        setShowModal={setShowConfirmDeleteSelected}
        handleConfirm={handleDeleteSelectedConfirm}
        title="Remove Selected Roles"
        confirmButtonText="Remove"
        body={
          <span className="fs-5">
            Are you sure you want to remove selected roles?
          </span>
        }
      />
      <RoleSearchModal
        showModal={showRoleSearch}
        setShowModal={setShowRoleSearch}
        handleAdd={(items: Array<IRoleData>) => {
          const rolesToAdd = items.map((item: IRoleData) => {
            return item.id
          })
          addUserRoles(user.staff_id, rolesToAdd, () => {
            getUser(user.staff_id)
            toast.success('Role(s) Added To User')
            setShowRoleSearch(false)
          })
        }}
        existing={user.roles}
      />
      <UserProfileSearchModal
        showModal={showUserProfileSearch}
        setShowModal={setShowUserProfileSearch}
        handleAdd={(item: IUserProfileData) => {
          addUserRolesFromProfile(user.staff_id, item.id, () => {
            getUser(user.staff_id)
            toast.success('Role(s) Added To User')
            setShowUserProfileSearch(false)
          })
        }}
      />
    </div>
  )
}

export default UserRoles
